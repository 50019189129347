/* for ph view */
@media (max-width: 831px) {
  #cqd-layout {
    overflow: auto;
    margin-left: 0;
    position: relative;
  }
  .divied {
    display: none;
  }
  .navbar {
    display: none;
  }
  .switch-btn {
    display: none;
  }
  .ph-menu {
    font-size: 20px;
    margin-right: 10px;
    display: inline-block;
  }
  #header {
    width: 100vw;
    display: grid;
    grid-template-columns: 55px 150px auto;
    height: 48px;
    background-color: #ebdcf9;
    position: fixed;
  }
  .nav-logo {
    display: flex;
  }
  .new-layout {
    height: calc(100vh - 48px);
    overflow: auto;
    margin-top: 48px;
  }
  .main-layout {
    display: grid;
    grid-template-columns: 0px Auto;
    width: 100%;
    height: 100vh;
    overflow: hidden;
  }
  .nav-company-info {
    display: grid;
    grid-template-rows: 50% 50%;
  }
  .text-overflow {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    font-weight: bold;
  }
}

/* for laptop version */
@media (min-width: 831px) {
  #cqd-layout {
    margin-left: 0;
  }
  #header {
    display: grid;
    grid-template-columns: 500px auto auto;
    height: 38px;
    background-color: #ebdcf9;
  }
  .text-overflow {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    padding-top: 8px;
    font-weight: bold;
    text-align: start;
  }
  .divied {
    margin: 0px 10px;
  }
  .nav-logo {
    display: none;
  }
  .ph-menu-cotainer {
    display: none;
  }
  .ph-menu {
    font-size: 10px;
    margin-right: 20px;
    display: none;
  }
  .switch-btn {
    display: flex;
  }
  .navbar {
    height: 100vh;
  }
  .new-layout {
    height: calc(100vh - 38px);
    overflow: auto;
  }
  .main-layout {
    display: grid;
    grid-template-columns: 56px Auto;
    width: 100%;
    height: 100vh;
    overflow: hidden;
  }
  .nav-company-info {
    display: grid;
    grid-template-columns: 50% 50%;
  }
}

.ph-menu:hover {
  color: royalblue;
  cursor: pointer;
}
.ant-menu-submenu > .ant-menu {
  background-color: white;
}
.ph-menu-bar {
  position: fixed;
  width: 0vw;
  height: 100vh;
  background-color: #f2f2f2;
  z-index: 200;
  overflow: auto;
  left: 0;
  padding: 10px;
  animation-name: open-menu;
  animation-duration: 200ms;
  animation-timing-function: ease-in;
  animation-fill-mode: both;
  max-width: 300px;
}

@keyframes open-menu {
  0% {
    width: 0vw;
    height: 100vh;
  }
  100% {
    width: 100vw;
    height: 100vh;
    border-radius: 0%;
    left: 0;
  }
}
