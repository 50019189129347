.attatchableField {
  width: 100%;
}

.attatchableField :global(.ant-form-item-label) {
  padding: 0px;
}

.attatchableField label {
  width: 100%;
}

.attatchableField :global(.ant-row) {
  width: 100%;
}

.attachLabel {
  color: var(--primary-color);
  cursor: pointer;
}

.dragging {
  border: 4px dashed #ccc;
}
.attachRow {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  /* margin-bottom: 10px; */
}

.fileIconBox {
  /* display: flex;
  flex: 0 0 32px;
  justify-content: center;
  align-items: center; */
  height: 32px;
  width: 32px;
  font-size: 32px;
  line-height: 32px;
  border-radius: 4px;
  /* margin-left: 8px; */
  margin-bottom: 16px;
  cursor: pointer;
  /* background-color: #f2994a; */
}
.fileIconBoxforTerms {
  /* display: flex;
  flex: 0 0 32px;
  justify-content: center;
  align-items: center; */
  height: 32px;
  width: 32px;
  font-size: 17px;
  line-height: 17px;
  border-radius: 4px;
  /* margin-left: 8px; */
  /* margin-top: 16px; */
  cursor: pointer;
  /* background-color: #f2994a; */
}

.deleteBtn {
  height: 32px;
  margin-bottom: 16px;
  padding: 8px;
  cursor: pointer;
  font-size: 14px;
  line-height: 16px;
  color: var(--primary-color);
}
.deleteBtnMulti {
  /* height: 32px;
  margin-bottom: 16px; */
  /* padding: 8px; */
  cursor: pointer;
  font-size: 12px;
  /* line-height: 16px; */
  color: var(--error-color);
}

.deleteBtnTerm {
  height: 32px;
  padding: 8px;
  cursor: pointer;
  font-size: 11px;
  color: #eb5757;
}

.previewModal {
  /* height: 75vh; */
  /* width: calc(550px + 24px); */
  /* width: 90vw !important; */
  /* height: 90vh !important; */
}

.previewFrame {
  height: 100%;
  width: 98%;
  /* min-height: 70vh; */
  /* min-width: 550px; */
  /* height: 60%;
  width: 75%; */
}

.mainDiv {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.attchDiv {
  display: flex;
  flex-direction: row;
  align-items: center;
}
