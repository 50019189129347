/* for ph view */
@media (max-width: 831px) {
  .tabsLayout {
    display: grid;
    grid-template-rows: auto;
    width: 100%;
  }
  .component1 {
    display: flex;
    justify-content: start;
    align-items: start;
    flex-direction: column;
    padding-right: 10px;
  }
  .component2 {
    display: flex;
    justify-content: start;
    align-items: start;
    flex-direction: column;
    padding-right: 10px;
  }
  .component3 {
    display: flex;
    justify-content: start;
    align-items: start;
    flex-direction: column;
    padding-right: 10px;
  }
  .inputStyles {
    width: 90%;
    max-width: 320px;
  }
}
/* for laptop version */
@media (min-width: 831px) {
  .tabsLayout {
    display: grid;
    grid-template-columns: auto auto auto;
    width: 70%;
  }
  .component1 {
    display: flex;
    justify-content: start;
    align-items: start;
    flex-direction: column;
    border-right: 1px solid black;
    padding: 15px 15px 15px 0px;
  }
  .component2 {
    display: flex;
    justify-content: start;
    align-items: start;
    flex-direction: column;
    /* border-right: 1px solid black; */
    padding: 15px;
  }
  .component3 {
    display: flex;
    justify-content: start;
    align-items: start;
    flex-direction: column;
    padding: 15px;
  }
  .inputStyles {
    width: 90%;
    max-width: 300px;
    min-width: 200px;
  }
}
