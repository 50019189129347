.lvl1List {
  width: 100%;
}

.footerRow {
  border-bottom: 1px solid #f0f0f0;
  padding: 8px 8px;
  display: flex;
  justify-content: space-between;
  margin-right: -10px;
}

.footer {
  min-width: 400px;
}

.footerWrap {
  /* font-weight: var(--title-font-weight); */
  padding: 0px var(--padding-xs);
  text-align: left;
  display: flex;
  width: 100%;
  justify-content: flex-end;
}

.footerLabel {
  color: var(--primary-color);
  width: 40%;
  text-align: left;
  display: inline-block;
  font-weight: 500;
}

.footerAmount {
  text-align: right;
  width: 40%;
  display: inline-block;
  color: var(--text-color);
  word-wrap: break-word;
}

.select {
  width: 100%;
}

/* .deleted_row{
  display: none;
} */
/* for ph view */
@media (max-width: 831px) {
  .tabsLayout {
    display: grid;
    grid-template-rows: auto;
    width: 100%;
  }
  .component1 {
    display: flex;
    justify-content: start;
    align-items: start;
    flex-direction: column;
    padding-right: 10px;
  }
  .component2 {
    display: flex;
    justify-content: start;
    align-items: start;
    flex-direction: column;
    padding-right: 10px;
  }
  .component3 {
    display: flex;
    justify-content: start;
    align-items: start;
    flex-direction: column;
    padding-right: 10px;
  }
  .inputStyles {
    width: 90%;
    max-width: 320px;
  }
}
/* for laptop version */
@media (min-width: 831px) {
  .tabsLayout {
    display: flex;
    grid-template-columns: auto auto auto;
    width: 80%;
  }
  .component1 {
    display: flex;
    justify-content: start;
    align-items: start;
    flex-direction: column;
    border-right: 1px solid black;
    padding: 15px;
  }
  .component2 {
    display: flex;
    justify-content: start;
    align-items: start;
    flex-direction: column;
    border-right: 1px solid black;
    padding: 15px;
  }
  .component3 {
    display: flex;
    justify-content: start;
    align-items: start;
    flex-direction: column;
    padding: 15px;
  }
  .inputStyles {
    width: 90%;
    max-width: 280px;
  }
}
