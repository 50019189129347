.commonContent {
  padding-left: calc(var(--margin-lg) + var(--margin-xs));
  padding-right: var(--margin-lg);
  padding-bottom: var(--margin-lg);
}

.vendorTypeOption {
  margin-bottom: var(--margin-md);
}
.highlighted-text {
  background-color: yellow;
  font-weight: bold;
}
.summaryDiv {
  font-size: 16px;
  font-style: normal;
  /* font-weight: 700; */
  line-height: 24px;
  letter-spacing: 0.4399999976158142px;
  text-align: left;
  /* margin-bottom: 1em; */
}
.footerWrap {
  /* font-weight: var(--title-font-weight); */
  padding: 0px var(--padding-xs);
  text-align: left;
  display: flex;
  width: 100%;
  justify-content: flex-end;
  margin-top: 1em;
}

.dynamic-delete-button {
  position: relative;
  top: 20px;
  margin: 0 8px;
  color: #999;
  font-size: 24px;
  cursor: pointer;
  transition: all 0.3s;
}
.dynamic-delete-button:hover {
  color: #777;
}
.dynamic-delete-button[disabled] {
  cursor: not-allowed;
  opacity: 0.5;
}

.a::before {
  display: inline-block;
  margin-right: 4px;
  color: #eb5757;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: "*";
}
