@import "../../../app.theme.less";

// :global(#cqd-layout) {
//   border: 10px dashed black;
// }

.commonContent {
  padding-left: calc(var(--margin-lg) + var(--margin-xs));
  padding-right: 0;
  padding-bottom: 0;
  // overflow-x: scroll;

  // :global(.ant-table) {
  :global(.ant-table-content) {
    min-height: 500px;
    min-height: calc(100vh - 120px);
    min-width: 100%;
  }
}

@media (max-width: 768px) {
  .commonContent {
    padding-left: var(--margin-sm);
  }
}

@media (max-width: 576px) {
  .commonContent {
    padding-left: var(--margin-xs);
  }
}

@media (max-width: 480px) {
  .commonContent {
    padding-left: var(--margin-xss);
  }
}

.totalTitle {
  display: inline-block;
  padding-right: @padding-xss;
}

.table {
  composes: table from "../../../fragment/line-items/LineItem.module.css";

  table {
    border-collapse: collapse;
  }

  td {
    border: 2px solid @white;
  }
}

.warnIcon {
  font-size: 18px;
  color: @error-color;
}

.rowValidationError {
  tr > td {
    // border: 2px solid @error-color;
    border-style: double;
    border-bottom: 3px double #eb5757 !important;
  }
}

.toolTipErrorUL {
  font-size: @font-size-base;
}

.selectError {
  border: 0.5px solid red;
  width: 100%;
}

.mandatoryDot::before {
  content: "* ";
  color: @error-color;
}

.addableColumnHead {
  display: flex;
  justify-content: space-between;
  width: 100%;

  .addableColTitle {
    //
  }

  .addBtn {
    color: @processing-color;
    font-size: smaller;
    font-weight: bold;
    cursor: pointer;
    margin-top: 3px;
    margin-right: @margin-xss;
  }
}
