.container {
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100vh;
}

.middle_container {
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.unauthorized_image {
  width: 80px;
  height: 80px;
}

.unauthorized_text {
  text-align: center;
  width: 60%;
  padding: 20px 0;
}
