.vendorTransaction {
  border-radius: 5px;
  font-size: 12px !important;
  white-space: normal;
  font-weight: 600;
  margin-right: 0;
  margin-top: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
}
