.commonContent {
  padding-left: calc(var(--margin-lg) + var(--margin-xs));
  padding-right: var(--margin-lg);
  padding-bottom: var(--margin-lg);
}

.invoice {
  font-size: 12px;
  margin-top: 5px;
  /* color: var(--primary-color); */
  /* font-weight: 600; */
  margin-left: 5px;
  opacity: 1;
}

@media (max-width: 768px) {
  .commonContent {
    padding-left: var(--margin-sm);
    padding-right: var(--margin-sm);
  }
}

@media (max-width: 576px) {
  .commonContent {
    padding-left: var(--margin-xs);
    padding-right: var(--margin-xs);
  }
}

@media (max-width: 480px) {
  .commonContent {
    padding-left: var(--margin-xss);
    padding-right: var(--margin-xss);
  }
}
