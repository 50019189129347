/* Common Class */

.w-100 {
  width: 100%;
}
.w-50 {
  width: 50%;
}
.w-70 {
  width: 70%;
}

.mb-12::after {
  /* margin-bottom: 12px; */
  content: " ";
  display: block;
  width: 12px;
  height: 14px;
}

.min-h-full {
  min-height: 100vh;
}

.hidden {
  visibility: hidden;
}

.termsModal {
  /* width: 500px; */
  height: 600px;
  /* overflow: scroll; */
}

.tdsPayModal {
  width: 230px;
  /* height: 600px; */
  /* overflow: scroll; */
}

.wip-icon {
  color: var(--disabled-color) !important;
}

/* TEXT CLASSES */
.text-grey-1 {
  color: var(--grey-1) !important;
}

.text-grey-2 {
  color: var(--grey-2) !important;
}

.text-grey-3 {
  color: var(--grey-3) !important;
}

.text-grey-4 {
  color: var(--grey-4) !important;
}

.text-error {
  color: var(--error-color) !important;
}

/* BACKGROUND COLOR CLASSES */

.bg-primary {
  background: var(--primary-color) !important;
}

.bg-info {
  background: var(--info-color) !important;
}

.bg-success {
  background: var(--success-color) !important;
}

.bg-processing {
  background: var(--processing-color) !important;
}

.bg-grey-1 {
  background: var(--grey-1) !important;
}

.bg-grey-2 {
  background: var(--grey-2) !important;
}

.bg-grey-3 {
  background: var(--grey-3) !important;
}
/* BORDER COLOR CLASSES */

.border-color-primary {
  border-color: var(--primary-color) !important;
}

.border-color-info {
  border-color: var(--info-color) !important;
}

.border-color-success {
  border-color: var(--success-color) !important;
}

.border-color-processing {
  border-color: var(--processing-color) !important;
}

/* BORDER COLOR CLASSES */

.text-color-primary {
  color: var(--primary-color) !important;
}

.text-color-info {
  color: var(--info-color) !important;
}

.text-color-success {
  color: var(--success-color) !important;
}

.text-color-processing {
  color: var(--processing-color) !important;
}

.dndContainerDragOver {
  border: 2px dotted var(--primary-color);
  min-height: 100vh;
}

.dragOverLabel {
  position: absolute;
  display: none;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background-color: rgba(47, 128, 237, 0.12);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: var(--heading-3-size);
  color: var(--grey-2);
  z-index: 1000;
}

/* .tanVerify {
  padding: 8px;
  font-weight: 1400;
  font-size: 25px;
  color: green;
  margin-left: 14px;
  margin-top: 28px;
} */

.deleteCfmDlgDetail {
  font-size: 12px;
  margin-top: 5px;
  /* color: var(--primary-color); */
  /* font-weight: 600; */
  margin-left: 5px;
  opacity: 1;
}

.p-rel {
  position: relative;
}
@media (max-width: 576px) {
  /* Add your styles for small screens here */
  .addClientInfos {
    display: block;
  }
}

/* For screens 576px and above */
@media (min-width: 576px) {
  /* Add your styles for larger screens here */
  .addClientInfos {
    display: flex;
  }
}
