.modalWidth {
  width: 30% !important;
}
.commonContent {
  padding-left: calc(var(--margin-lg) + var(--margin-xs));
  padding-right: var(--margin-lg);
  padding-bottom: var(--margin-lg);
}

.profileImageContainer {
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background: var(--grey-2);
  border-radius: 16px;
  overflow: hidden;
  border: var(--grey-2) 1px solid;
}

.footerWrap {
  padding: 0px var(--padding-xs);
  text-align: left;
  display: flex;
  width: 100%;
  justify-content: flex-end;
  margin-top: 1em;
}

.summaryDiv {
  font-size: 16px;
  font-style: normal;
  line-height: 24px;
  letter-spacing: 0.4399999976158142px;
  text-align: left;
}

.publish {
  padding-left: 40px;
  padding-right: 40px;
}

.draft_and_restore {
  display: flex;
  justify-content: flex-end;
  margin-top: -20px;
}

.draft {
  display: flex;
  justify-content: flex-end;
}

.draft_restore_font {
  font-size: 12px;
}

.reset_button {
  padding: 0;
  margin-top: -3px;
  margin-right: 3px;
  margin-left: 3px;
}

.sample_button {
  margin-bottom: 20px;
  font-size: 16px;
  margin-left: -15px;
}

.editor {
  /* width: clamp(0px,100%,800px); */
  width: 80vw;
  max-width: 900px;
  min-width: 290px;
}

.alert_message {
  max-width: 8in;
}
/* for ph view */
@media (max-width: 831px) {
  .actionButtons {
    display: none;
  }
}
/* for laptop version */
@media (min-width: 831px) {
  .actionMenu {
    display: none;
  }
}
