.tableDataEllipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.fieldInfoWrap {
  margin-top: 10px;
  margin-left: -5px;
}

.fieldInfoStyle {
  max-width: 98px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
