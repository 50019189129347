.base {
  display: block;
  /* text-align: center; */
  width: 128px;
  margin-bottom: var(--margin-md);
}

.base :global(.ant-upload-list-item-progress) {
  bottom: 54px !important;
}

.base :global(.ant-upload-list-picture-card-container) {
  height: 128px;
  width: 128px;
}

.circularBase {
  composes: base;
}

.squareBase {
  composes: base;
}

.squareBase :global(.ant-upload-list-item) {
  padding: 0px;
}

.circularBase :global(.ant-upload-list-item) {
  border-radius: 99999px;
  padding: 0px;
}

.circularBase :global(.ant-upload-list-item-info) {
  border-radius: 99999px;
}

.uploadAction {
  margin-left: 7px;
  font-weight: bold;
  width: 100%;
  display: block;
  /* text-align: center; */
  margin-top: var(--margin-xs);
}

.profilePhoto {
  padding: 4px;
  height: 90px;
  width: 90px;
  position: relative;
  border-radius: 50%;
  overflow: hidden;
  /* object-fit: contain; */
}
