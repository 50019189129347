.tab {
  max-width: 100%;
  min-width: 860px;
  overflow: auto;
}

.lvl1List {
  width: 100%;
}

/* TODO: simplyfy this */
.lvl1List :global(.ant-collapse-content-active) {
  box-shadow: inset -1px 3px 4px -4px rgb(0 0 0 / 3%),
    inset 0 6px 16px 0 rgb(0 0 0 / 0%), inset 0 9px 28px 8px rgb(0 0 0 / 0.02);
}

.lvl1List :global(.ant-list-item-meta) {
  align-items: baseline;
}

.fieldInfo {
  padding-top: 10px;
}

.lvl1List :global(.ant-list-item-meta-content) {
  display: flex;
}

.lvl1List :global(.ant-list-item-meta-title) {
  flex-basis: 60%;
  flex: 1;
  text-overflow: ellipsis;
}

.lvl1List :global(.ant-list-item-meta-description) {
  flex-basis: 210px;
  text-align: right;
}

.lvl2DrawerTbl {
  margin-top: var(--margin-sm);
  /* background-color: #9e9e9e69 !important; */
}

.lvl2DrawerTbl :global(.ant-table-row-level-0) {
  background: var(--gray-4);
  font-weight: 500;
}

.lvl2DrawerTbl.ant-table-row-level-0.tr:hover {
  background: none;
}

.levelOneRow {
  cursor: pointer;
}

.completedRow {
  composes: levelOneRow;
  color: var(--grey-2);
}

.currency {
  font-family: var(--font-system);
  border-right: 1px solid var(--grey-4);
}
.gstin {
  /* color: var(--grey-2); */
  filter: alpha(opacity=50);
  text-align: left;
  font-size: 10px;
  margin-top: -12;
  color: var(--primary-color);
  font-weight: 600;
}
.expid {
  /* color: var(--grey-2); */
  filter: alpha(opacity=50);
  /* text-align: center; */
  font-size: 10px;
  margin-top: -12;
  color: var(--primary-color);
  font-weight: 600;
  /* text-indent: -75px; */
}

.notgst {
  /* color: var(--grey-2); */
  filter: alpha(opacity=50);
  text-align: center;
  font-size: 10px;
  margin-top: -12;
  color: var(--primary-color);
  font-weight: 600;
  text-indent: -75px;
}

.drawerHeaderAlign {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.typeParentDiv {
  display: block;
  flex-wrap: wrap;
  justify-content: flex-start;
  white-space: nowrap;
  width: 160px;
}
.typeDivWidth {
  width: 100%;
  display: inline-block;
  /* white-space: nowrap; */
}
.empNameDivWidth {
  width: 100%;
}
.matchColor {
  color: var(--success-color);
}
.notMatchColor {
  color: var(--error-color);
}
.percialColor {
  color: #000;
}
.legendContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 20px;
}
.notMatchLegend {
  height: 20px;
  width: 20px;
  background-color: var(--error-color);
}
.matchLegend {
  height: 20px;
  width: 20px;
  background-color: var(--success-color);
  margin-left: 20px;
}
.percialMatchLegend {
  height: 20px;
  width: 20px;
  background-color: #000;
  margin-left: 20px;
}

.legendText {
  margin-left: 10px;
}

.actionCompliance {
  display: flex;
  justify-content: center;
}

.tdsNameColumn {
  min-width: 138px;
}

.otherNameColumn {
  min-width: 88px;
}

.cmpTypeClm {
  min-width: 94px;
  max-width: 154px;
}

.cmpDueDateClm {
  max-width: 94px;
}

.cmpForPeriodClm {
  max-width: 160px;
  /* padding-left: 16px; */
}

.complianceTotalAmountColumn {
  min-width: 110px;
}

.cmpFilingFeeClm {
  min-width: 81px;
}

.cmpLateFineClm {
  min-width: 80px;
}

/* Medium screen / desktop */
@media (min-width: 768px) {
  .tab {
    max-width: 95%;
  }
}

/* Large screen / wide desktop */
@media (min-width: 992px) {
  .tab {
    max-width: 82.5%;
  }
}

/* Extra large screen / full hd */
@media (min-width: 1200px) {
  .tab {
    max-width: 72%;
  }
}

/* Extra extra large screen / large desktop */
@media (min-width: 1600px) {
  .tab {
    max-width: 60%;
  }
}
