.actionField {
  width: 100%;
}

.actionField :global(.ant-form-item-label) {
  padding: 0px;
}

.actionField label {
  width: 100%;
}

.actionField :global(.ant-row) {
  width: 100%;
}

.actionLabel {
  color: var(--processing-color);
  cursor: pointer;
}
