.modal {
  width: 700px !important;
}
@media (min-width: 992px) {
  .modal {
    width: 820px !important;
  }
}

@media (min-width: 1200px) {
  .modal {
    width: 964px !important;
  }
}

.table :global(.ant-table-thead) > tr > th {
  color: var(--primary-color);
  border-bottom: 1px solid var(--grey-1) !important;
  padding: 12px 8px;
}

.table :global(.ant-table-container) {
  border-bottom: 1px solid var(--grey-1) !important;
}

/* .table :global(.ant-table-tbody) > tr > td {
  font-weight: var(--title-font-weight);
} */

.table :global(.ant-table-footer) {
  border-top: 1px solid var(--grey-1);
  border-bottom: 1px solid var(--grey-1);
  background-color: var(--white);
  color: var(--primary-color);
  padding: 0px;
}

.footerWrap {
  /* font-weight: var(--title-font-weight); */
  padding: 0px var(--padding-xs);
  text-align: left;
  display: flex;
  width: 100%;
  justify-content: flex-end;
}

.footer {
  min-width: 250px;
}

.footerRow {
  border-bottom: 1px solid #f0f0f0;
  padding: 8px 8px;
}

.footerLabel {
  color: var(--primary-color);
  width: 40%;
  text-align: left;
  display: inline-block;
}

.footerAmount {
  composes: currency from "../../../component/Currency.module.css";
  text-align: right;
  width: 60%;
  display: inline-block;
  color: var(--text-color);
}

.float_right {
  float: right;
}
