.wrapper {
  padding: var(--margin-lg);
}

.base {
  display: flex;
  align-items: center;
  line-height: var(--heading-3-line-height);
  /* padding-left:0; */
}

.bcLeftImgWrapper {
  margin-left: 2px;
  height: 44px;
  min-width: 44px;
  background-color: var(--grey-4);
  margin-right: 10px;
}

.bcLeftImg {
  height: 44px;
  width: 44px;
  object-fit: contain;
}

.backAction {
  margin-right: var(--margin-sm);
  cursor: pointer;
  opacity: 0.5;
}

.backAction:hover {
  opacity: 0.9;
  transition: all 1s;
}

.title {
  margin: 0px;
}

.title:not(:last-of-type) {
  margin-right: var(--margin-sm);
}

.separator {
  composes: title;
  position: relative;
  bottom: 2px;
}

.breadcumSubTexts {
  font-weight: 600;
  opacity: 0.8;
}

.tooltip {
  /* padding: 3px; */
  display: flex;
  /* flex: 1; */
  align-items: center;
  justify-content: center;
  /* background: red; */
  width: 30px;
}
/* .title:not(:last-child)::after {
  content: ">";
  display: block;
  font-size: var(--heading-3-size);
  color: var(--text-color);
  margin-right: var(--margin-sm);
  margin-left: var(--margin-sm);
} */

.stretch {
  flex: 1;
}

.actionWrap {
  display: block;
}

@media (max-width: 768px) {
  .wrapper {
    padding: var(--margin-sm);
  }
  /* .base {
    padding: var(--margin-sm);
  } */
  .backAction,
  .separator {
    height: var(--font-size-lg) !important;
  }
  .title {
    font-size: var(--font-size-lg) !important;
  }
}

@media (max-width: 576px) {
  .wrapper {
    padding: var(--margin-xs);
  }
  /* .base {
    padding: var(--margin-sm);
  } */
  .backAction,
  .separator {
    height: var(--heading-4-size) !important;
  }
  .title {
    font-size: var(--heading-4-size) !important;
  }
}

@media (max-width: 480px) {
  .wrapper {
    padding: var(--margin-xss);
  }
  /* .base {
    padding: var(--margin-sm);
  } */
}
/* for ph view */
@media (max-width: 831px) {
  .boxPhView {
    display: grid;
  }
  .boxPhLaptopView {
    display: none;
  }
}
/* for laptop version */
@media (min-width: 831px) {
  .boxPhView {
    display: none;
  }
  .boxPhLaptopView {
    display: block;
  }
}
