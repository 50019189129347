.summaryDiv {
  font-size: 16px;
  font-style: normal;
  /* font-weight: 700; */
  line-height: 24px;
  letter-spacing: 0.4399999976158142px;
  text-align: left;
  margin-bottom: 0.3em;
  margin-top: 0.5em;
}
.highlighted-text {
  background-color: yellow;
  font-weight: bold;
}
.tableDataEllipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.footerWrap {
  /* font-weight: var(--title-font-weight); */
  padding: 0px var(--padding-xs);
  text-align: left;
  display: flex;
  width: 100%;
  justify-content: flex-end;
  margin-top: 1em;
}

.pastDate {
  color: rgb(255, 0, 0);
  font-weight: 700;
}
.actionInvoice {
  display: flex;
  justify-content: center;
}

.upload_section {
  position: fixed;
  z-index: 10;
  right: var(--margin-lg);
  bottom: var(--margin-lg);
}

.invoiceTable {
  max-width: 100%;
  cursor: pointer;
}

.invoice {
  font-size: 12px;
  margin-top: 5px;
  /* color: var(--primary-color); */
  /* font-weight: 600; */
  margin-left: 5px;
  opacity: 1;
}

.highlighted-text {
  background-color: yellow;
  font-weight: bold;
}

@media screen and (min-width: 780px) {
  .invoiceTable {
    max-width: 99%;
  }
}

@media screen and (min-width: 1110px) {
  .invoiceTable {
    max-width: 89%;
  }
}
