.tableWrapper {
  width: 60%;
  cursor: pointer;
}

.table {
  min-width: "100%";
}

.tableCell {
  display: flex;
  justify-content: flex-end;
}

.errorDiv {
  color: crimson;
  width: 80px;
}
.normalDiv {
  width: 80px;
}

.statusDiv {
  width: 80px;
}

.completeDiv {
  width: 120px;
  display: flex;
  justify-content: space-around;
}

.statusBar {
  display: flex;
  margin-top: 6px;
  margin-bottom: 0px;
  width: 60%;
  align-items: baseline;
  min-width: 650px;
}

.inlineTextColor {
  color: #2f80fd;
  font-weight: 700;
}

.csvDownloadBtn {
  font-size: 12px;
  color: var(--primary-color);
  font-weight: 600;
  /* margin-left: var(--margin-md); */
  /* cursor: pointer; */
}

.csvDownloadBtn:hover {
  color: var(--primary-5);
  background-color: transparent;
}

.drwerHeading {
  /* color: #9E66E0; */
}

.countStyle {
  color: #6d6d6d;
  font-size: 12px;
}

.tableDataEllipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-size: 15px;
  margin-left: 15px;
  max-width: 550px;
}
