.summaryDiv {
  font-size: 16px;
  font-style: normal;
  /* font-weight: 700; */
  line-height: 24px;
  letter-spacing: 0.4399999976158142px;
  text-align: left;
  margin-bottom: 1em;
}

.modalWidth {
  width: 30% !important;
}

.profileImageContainer {
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background: var(--grey-2);
  border-radius: 16px;
  overflow: hidden;
  border: var(--grey-2) 1px solid;
}

.footerWrap {
  /* font-weight: var(--title-font-weight); */
  padding: 0px var(--padding-xs);
  text-align: left;
  display: flex;
  width: 100%;
  justify-content: flex-end;
  margin-top: 1em;
}

.summaryDiv {
  font-size: 16px;
  font-style: normal;
  /* font-weight: 700; */
  line-height: 24px;
  letter-spacing: 0.4399999976158142px;
  text-align: left;
  /* margin-bottom: 1em; */
}
