.sider {
  overflow: auto;
  height: 100vh;
  position: fixed;
  left: 0;
}

.menu {
  display: flex;
  flex-direction: column;
  height: 100%;
  border: none !important;
}

.menuItem {
  height: 50px !important;
  margin: 0 !important;
  padding: 7px 14px !important;
}

#overdueTag {
  background-color: var(--tag-color-overdue);
  width: 30px;
}

#TagForZero {
  background-color: var(--tag-color-zero);
  width: 30px;
}

#pendingTag {
  background-color: var(--tag-color-pending);
  width: 30px;
}

.menuItem:hover {
  background-color: var(--component-background-hover) !important;
  /* border-left: 4px solid #9b51e091 !important;
  padding-left: 10px !important; */
}
.userSubmenuInvoice:hover {
  background-color: var(--component-background-hover) !important;
  /* border-left: 4px solid #9b51e091 !important;
  padding-left: 10px !important; */
}

.menuItem:global(.ant-menu-item-selected) {
  background-color: var(--component-background-hover) !important;
  border-left: 4px solid #9b51e0;
  padding-left: 10px !important;
}

.lowerMenuItem {
  height: 54px !important;
  /* padding-top: var(--padding-xs) !important; */
  /* padding-bottom: var(--padding-xs) !important; */
  margin: 0px !important;
}

.lowerMenuItem:hover {
  background-color: var(--component-background-hover) !important;
}

.lowerMenuItem:global(.ant-menu-item-selected) {
  background-color: var(--component-background-hover) !important;
  padding-left: 11.5px !important;
  border-left: 4px solid #9b51e0;
}

.userSubmenu {
  margin: 21px auto 0px auto;
  width: 100%;
}

.userSubmenuInvoice {
  /* margin: 21px auto 0px auto;  */
  /* width: 100%; */
  height: 50px !important;
  margin: 0 !important;
  /* padding: 3px 14px !important; */
}

.userSubmenuInvoice:global(.ant-menu-submenu-selected) {
  background-color: var(--component-background-hover) !important;
  border-left: 4px solid #9b51e0;
  padding-right: 8px !important;
}

.userSubmenu > :global(.ant-menu-submenu-title) {
  padding: initial !important;
  width: 100%;
}
.userSubmenu:hover {
  opacity: 0.8;
}

.userSubmenuLvl2 > :global(.ant-menu-submenu-title) {
  height: 30px;
  line-height: 30px;
}

.userSubmenuPopup :global(.ant-menu-item) {
  font-size: var(--font-size-md);
  font-weight: 500;
}
.userSubmenuPopupInvoice :global(.ant-menu-item) {
  font-size: var(--font-size-md);
  font-weight: 500;
}
.userSubmenuLvl2 > :global(.ant-menu-submenu-title) > span:nth-child(2) {
  font-size: var(--font-size-md);
  font-weight: 500;
}

.nameP {
  color: white;
  font-weight: 700;
  font-size: 17;
}

.divProfile {
  width: 100%;
  display: flex;
  flex-direction: column;
  line-height: 80%;
}

.emailSpan {
  color: #acccf6;
  font-weight: 700;
  font-size: 14;
  margin-bottom: 20px;
}
.emailSpan2 {
  color: #acccf6;
  font-weight: 700;
  font-size: 14;
  margin-bottom: 20px;
  margin-top: 28px;
}

.curCompanySubMenu {
  composes: userSubmenuLvl2;
}

.changePassMenuItem {
  display: flex;
  align-items: center;
  /* display: block !important; */
  composes: userSubmenuLvl2;
  border: none;
  /* text-align: left !important; */
}

.userSubmenuLvl3 {
  composes: userSubmenuLvl2;
}

.userSubmenuPopup {
  background: transparent;
}

.userSubmenuPopup :global(.ant-menu-sub) {
  border-radius: var(--border-radius-md);
}

.userSubmenuPopup :global(.ant-menu-item:not(:last-child)) {
  margin-bottom: 0px !important;
  padding-bottom: var(--padding-xs) !important;
}

.userSubmenuPopupInvoice :global(.ant-menu-item:not(:last-child)) {
  margin-bottom: 0px !important;
}

.userSubmenuPopupInvoice :global(.ant-menu-sub) {
  border-radius: var(--border-radius-md);
  padding-left: -90px;
}

.userSubmenuPopupInvoice {
  background: transparent;
}

.invoiceMenuGroup :global(.ant-menu-item-group-title) {
  display: "flex";
  justify-content: space-around;

  /* align-content: space-around; */
}

.userPic {
  text-align: center;
  background: var(--primary-color);
  height: 27px;
  width: 27px;
  margin: 0 auto;
  overflow: hidden;
  border-radius: 50%;
  line-height: 27px;
  color: rgb(255, 255, 255);
  font-weight: bold;
  font-size: 20px;
}
.userPicWrapper {
  height: 32px;
  width: 32px;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.userPicWrapper2 {
  height: 36px;
  width: 36px;
  margin: 0 auto;
  overflow: hidden;
  border: transparent 2px solid;
  border-radius: 50%;
  background-image: linear-gradient(to right, #1083b8, #cc00c2);
  cursor: pointer;
}

.subMenuInvoiceIcon {
  max-width: 14px;
  display: flex;
  flex-direction: row;
  margin-left: -10px;
  justify-content: start;
  opacity: 0.7;
}

.subMenuInvoiceLabel {
  align-items: center;
  justify-content: center;
  margin-left: 10px;
  display: flex;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
}

.icon {
  height: 28px;
  width: 28px;
  font-size: 28px;
}
.sicon {
  border: 1px solid red;
}

.expander {
  flex: 1;
}

.logoMenu {
  width: 41px;
}

.frgetPasslabel {
  margin-bottom: 5px;
  font-size: 12px;
  font-weight: bold;
}

.frgetPasslabel1 {
  margin-top: 15px;
  margin-bottom: 5px;
  font-size: 12px;
  font-weight: bold;
}
.newPass {
  width: 100%;
}

.menuItemCss {
  font-size: 8px;
  font-weight: 800;
  line-height: 2px;
  margin-top: 4px;
}

.belowMenuItemCss {
  font-size: 8px;
  font-weight: 800;
  line-height: 2px;
  margin-top: 10px;
}
.belowSettingsMenuItemCss {
  font-size: 8px;
  font-weight: 800;
  line-height: 2px;
  margin-top: 5px;
  margin-left: -2px;
}

.invoiceMenuGroup :global(.ant-menu-item) {
  height: 40px !important;
  line-height: 40px !important;
}

.invoiceMenuGroup :global(.ant-menu-item-group-title) {
  padding: 0;
}

/* new phone layout */
/* for ph view */
@media (max-width: 831px) {
  #cqd-layout {
    overflow: auto;
    margin-left: 0;
    position: relative;
  }
  .divied {
    display: none;
  }
  .navbar {
    display: none;
  }
  .switch-btn {
    display: none;
  }
  .phMenu {
    font-size: 20px;
    margin-right: 10px;
    display: inline-block;
  }
  .header {
    width: 100vw;
    display: grid;
    grid-template-columns: 55px 1fr auto;
    height: 48px;
    background-color: #ebdcf9;
    position: fixed;
  }
  .navLogo {
    display: flex;
  }
  .newLayout {
    height: calc(100vh - 48px);
    overflow: auto;
    margin-top: 48px;
    position: relative;
  }
  .mainLayout {
    display: grid;
    grid-template-columns: 0px Auto;
    width: 100%;
    height: 100vh;
    overflow: hidden;
  }
  .companyInfoMainLayout {
    margin-left: 20px;
    display: grid;
    grid-template-columns: 1fr auto;
  }
  .navCompanyInfo {
    display: grid;
    grid-template-rows: 50% 50%;
    cursor: pointer;
  }
  .companyInfo1 {
    margin-top: 3px;
  }
  .copyButton {
    display: none;
  }
  .copyButton2 {
    position: absolute;
    z-index: 100;
    background-color: #5c5c5b;
    padding: 5px 10px;
    color: white;
  }
  .companyInfo2 {
    margin-top: -3px;
    color: rgb(130, 126, 126);
    position: relative;
  }
  .userDetailsIn {
    display: none;
  }
  .textOverflow {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    font-weight: bold;
  }
}

/* for laptop version */
@media (min-width: 831px) {
  #cqd-layout {
    margin-left: 0;
  }
  .companyInfo2 {
    color: rgb(130, 126, 126);
    position: relative;
  }
  .companyInfoMainLayout {
    margin-left: 20px;
    display: grid;
    grid-template-columns: auto auto;
  }
  .copyButton2 {
    display: none;
  }
  .header {
    display: grid;
    grid-template-columns: 600px auto auto;
    height: 38px;
    background-color: #ebdcf9;
  }
  .textOverflow {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    padding-top: 8px;
    font-weight: bold;
    text-align: start;
  }
  .copyButton {
    position: absolute;
    top: 3px;
    right: 0px;
    background-color: #5c5c5b;
    padding: 5px 20px;
    color: white;
  }
  .divied {
    margin: 0px 10px;
  }
  .navLogo {
    display: none;
  }
  .phMenuCotainer {
    display: none;
  }
  .phMenu {
    font-size: 10px;
    margin-right: 20px;
    display: none;
  }
  .switch-btn {
    display: flex;
  }
  .navbar {
    height: 100vh;
  }
  .newLayout {
    height: calc(100vh - 38px);
    overflow: auto;
    position: relative;
  }
  .mainLayout {
    display: grid;
    grid-template-columns: 56px Auto;
    width: 100%;
    height: 100vh;
    overflow: hidden;
  }
  .userDetailsIn {
    position: relative;
    margin-left: 14px;
  }
  .userDetailsOut {
    display: none;
  }
  .navCompanyInfo {
    display: flex;
    justify-content: start;
    cursor: pointer;
  }
}

.phMenu:hover {
  color: royalblue;
  cursor: pointer;
}
.ant-menu-submenu > .ant-menu {
  background-color: white;
}
.ph-menu-bar {
  position: fixed;
  width: 0vw;
  height: 100vh;
  background-color: #f2f2f2;
  z-index: 200;
  overflow-x: hidden;
  left: 0;
  padding: 10px;
  animation-name: open-menu;
  animation-duration: 200ms;
  animation-timing-function: ease-in;
  animation-fill-mode: both;
  max-width: 300px;
}

@keyframes open-menu {
  0% {
    width: 0vw;
    height: 100vh;
  }
  100% {
    width: 100vw;
    height: 100vh;
    border-radius: 0%;
    left: 0;
  }
}
