.setupButton {
  background-color: "#219653";
  color: "white";
  border-color: "#219653";
}

.dragger {
  margin-top: -16px;
  width: 100%;
  height: 100%;
}
.highlighted-text {
  background-color: yellow !important;
  font-weight: bold;
}
.dragger:global(.ant-upload.ant-upload-drag) {
  border: none;
  background-color: #fff;
}

.tableDataEllipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.invoice {
  font-size: 12px;
  margin-top: 5px;

  margin-left: 5px;
  opacity: 1;
}

.dragger :global(.ant-upload-drag-container) {
  background-color: #fff !important;
}

.dragger:global(.ant-upload-drag-hover) {
  background: #fafafa !important;
  border: 1px dashed #096dd9 !important;
}

.dragger:global(.ant-upload-drag-hover) :global(.ant-upload-drag-container) {
  background: #fafafa !important;
}

/* .tableWrap {
  max-width: 80%;
  overflow-x: auto;
  margin-left: 3em;
} */

@media (max-width: 1399px) {
  .tableWrap {
    max-width: 90%;
  }
}
@media (max-width: 1000px) {
  .tableWrap {
    max-width: 100%;
  }
}

.table {
  min-width: 1100px;
}

.purTable {
  max-width: 1050px;
  cursor: pointer;
}

.successBtn {
  background-color: var(--success-color) !important;
}
.footerWrap {
  padding: 0px var(--padding-xs);
  text-align: left;
  display: flex;
  width: 100%;
  justify-content: flex-end;
  margin-top: 1em;
}
.summaryDiv {
  font-size: 16px;
  font-style: normal;
  line-height: 24px;
  letter-spacing: 0.4399999976158142px;
  text-align: left;
  /* margin-left: 42px; */
  margin-top: 20px;
}

.actionExpense {
  display: flex;
  justify-content: center;
}

.upload_section {
  position: fixed;
  z-index: 10;
  right: var(--margin-lg);
  bottom: var(--margin-lg);
}

.table_data_ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
