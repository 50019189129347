.attatchableField {
  width: 70%;
}

.attatchableField :global(.ant-form-item-label) {
  padding: 0px;
}

.attatchableField label {
  width: 100%;
}

.attatchableField :global(.ant-row) {
  width: 100%;
}

.attachLabel {
  color: var(--processing-color);
  cursor: pointer;
}

.fileIconBox {
  /* display: flex;
  flex: 0 0 32px;
  justify-content: center;
  align-items: center; */
  height: 32px;
  width: 32px;
  font-size: 24px;
  line-height: 38px;
  border-radius: 4px;
  margin-left: 0px;
  margin-bottom: 16px;
  cursor: pointer;
  /* background-color: #f2994a; */
}

.deleteBtn {
  height: 32px;
  margin-bottom: 16px;
  padding: 8px;
  cursor: pointer;
  font-size: 12px;
  line-height: 16px;
  color: var(--primary-color);
}

.previewModal {
  /* min-height: 75vh; */
  min-width: calc(550px + 24px);
  /* max-width: 90vw !important;
  max-height: 90vh !important; */
}

.previewFrame {
  height: 100%;
  width: 100%;
  min-height: 70vh;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
}
