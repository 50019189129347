.base {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  justify-content: center;
  text-align: center;
  width: 100%;
}

.base::before,
.base > * {
  margin-bottom: 20px;
}

.base > :last-child {
  margin-bottom: 0px;
}

.base > * {
  max-width: 400px;
}

.visual {
  color: var(--disabled-color);
  font-size: calc(var(--font-size-lg) * 4);
}
