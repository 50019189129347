.summaryDiv {
  font-size: 16px;
  font-style: normal;
  /* font-weight: 700; */
  line-height: 24px;
  letter-spacing: 0.4399999976158142px;
  text-align: left;
  margin-bottom: 0.3em;
  margin-top: 0.5em;
}
.tableDataEllipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.paymentTable {
  max-width: 100%;
}

.instrumentId {
  color: blue;
  text-decoration: underline;
  cursor: pointer;
}

.highlighted-text {
  background-color: yellow;
  font-weight: bold;
}

@media screen and (min-width: 780px) {
  .paymentTable {
    max-width: 99%;
  }
}

@media screen and (min-width: 1110px) {
  .paymentTable {
    max-width: 89%;
  }
}
