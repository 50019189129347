* {
  box-sizing: border-box;
}

.hoverTransparancy:hover {
  opacity: 0.8;
  transition: all 0.3s;
}

.justifyCenter {
  justify-content: center;
}
.displayNone {
  display: none;
}
.displayBlock {
  display: block;
}
.displayFlex {
  display: flex;
}

.bodyWrap {
  width: 100%;
}

.blockWrap {
  /* width of ant-col-2 */
  padding-left: 8.33333333%;
  padding-right: 8.33333333%;
}

.standartBodyText {
  color: black;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  opacity: 0.65;
}

.standartHeading {
  color: black;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  opacity: 0.9;
}

.animatedText {
  color: var(--processing-color);
  background-image: -webkit-linear-gradient(
    92deg,
    var(--primary-color),
    var(--processing-color)
  );
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-animation: hue 10s infinite linear;
  animation: hue 10s infinite linear;
}
@-webkit-keyframes hue {
  from {
    -webkit-filter: hue-rotate(0deg);
  }
  to {
    -webkit-filter: hue-rotate(-360deg);
  }
}
@keyframes hue {
  from {
    -webkit-filter: hue-rotate(0deg);
  }
  to {
    -webkit-filter: hue-rotate(-360deg);
  }
}

.headerLinks {
  font-size: 12px;
}
@media (min-width: 786px) {
  .headerLinks {
    font-size: 12px;
  }
}
@media (min-width: 992px) {
  .headerLinks {
    font-size: 12px;
  }
}
@media (min-width: 1191px) {
  .headerLinks {
    font-size: 14px;
  }
}
@media (min-width: 1440px) {
  .headerLinks {
    font-size: 16px;
  }
}

.spaceHeaderWrap {
  /*   display: none;*/
  text-align: right;
}

.langSelectAltWrap {
  position: absolute;
  width: 90px;
  top: calc(60px + var(--margin-sm));
  right: 4.16666667%;
}

.dropdownSelectWrap {
  display: none !important;
}

.hamburger {
  margin-left: 16px;
  font-size: 18px;
  color: #828282 !important;
}

.heroBlockSpace {
  margin-top: 5px;
  gap: 8px;
  flex-direction: column;
  align-items: flex-start !important;
}
@media (min-width: 320px) {
  .heroBlockSpace {
    gap: 4px;
  }
}
@media (min-width: 768px) {
  .heroBlockSpace {
    margin-top: 30px;
    gap: 8px;
    flex-direction: row;
  }
}

.heroblockRow {
  display: flex;
  align-items: center;
  padding-top: 5px;
  padding-bottom: 5px;
  position: relative;
}

@media (min-width: 991px) {
  .heroblockRow {
    padding-top: 10px;
    padding-bottom: 10px;
  }
}
@media (min-width: 1199px) {
  .heroblockRow {
    padding-top: 30px;
    padding-bottom: 30px;
  }
}

.HeadLine {
  margin-bottom: 0px;
  font-weight: 600;
  font-size: 38px;
  line-height: 50px;
  /* color: rgba(0, 0, 0, 0.85);
  font-weight: 600;
  font-size: var(--heading-1-size);
  line-height: 1.23; */
}
@media (min-width: 320px) {
  .HeadLine {
    font-size: 24px;
    line-height: 33px;
  }
}
@media (min-width: 1199px) {
  .HeadLine {
    font-size: 32px;
    line-height: 42px;
  }
}

@media (min-width: 1440px) {
  .HeadLine {
    font-size: 40px;
    line-height: 50px;
  }
}

.primaryTextColor {
  color: #9b51e0;
}

.subLine {
  margin-top: 10px;
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 30px;
  color: #606060;
}
@media (min-width: 320px) {
  .subLine {
    margin-top: 5px;
    font-size: 19px;
    line-height: 27px;
  }
}

@media (min-width: 992px) {
  /* .subLine {
    font-size: 29px;
    line-height: 36px;
  } */
}
@media (min-width: 1199px) {
  .subLine {
    font-size: 22px;
    line-height: 32px;
  }
}
@media (min-width: 1440px) {
  .subLine {
    font-size: 27px;
    line-height: 40px;
  }
}

.heroblockCol {
  display: flex;
  align-items: center;
}
.heroBlockImages {
  width: 100%;
  margin: 0 auto;
  padding-top: 20px;
  display: flex;
  position: relative;
  /* width: 403px; */
  height: 403px;
  /* background-color: #9b51e00e; */
}
@media (max-width: 576px) {
  .heroBlockImages {
    height: 316px;
  }
}

/** -------------------- **/
.shadowDiv {
  box-sizing: border-box;
  border-radius: 50%;
  /* background-color: #9b51e00e; */
  background: rgba(255, 255, 255, 0.9);
  filter: drop-shadow(0px 4px 30px rgba(114, 28, 194, 0.15));
  position: absolute;
  width: 423px;
  height: 423px;
  right: 10px;
  top: 10px;
}

@media (min-width: 320px) {
  .shadowDiv {
    width: 244px;
    height: 244px;
  }
}
@media (max-width: 576px) {
  .shadowDiv {
    width: 243px;
    height: 243px;
    right: calc((84vw - 243px) / 2);
    top: 15px;
  }
}
@media (min-width: 992px) {
  .shadowDiv {
    width: 323px;
    height: 323px;
  }
}

@media (min-width: 1440px) {
  .shadowDiv {
    width: 394px;
    height: 394px;
    top: -30px;
  }
}

/** -------------------- **/
.heroBlockImg1 {
  box-sizing: border-box;
  border-radius: 50%;
  background-size: cover !important;
  position: absolute;
  width: 403px;
  height: 403px;
  right: 20px;
  top: 20px;
  background: url("/screenshot-1.jpeg");
  border: 4px solid #ffffff;
  filter: drop-shadow(0px 4px 30px rgba(114, 28, 194, 0.15));
}
@media (min-width: 320px) {
  .heroBlockImg1 {
    width: 224px;
    height: 224px;
  }
}
@media (max-width: 576px) {
  .heroBlockImg1 {
    width: 233px;
    height: 233px;
    right: calc((84vw - 233px) / 2);
  }
}
@media (min-width: 992px) {
  .heroBlockImg1 {
    width: 303px;
    height: 303px;
  }
}
@media (min-width: 1440px) {
  .heroBlockImg1 {
    width: 374px;
    height: 374px;
    top: -20px;
  }
}

/** -------------------- **/

.heroBlockImg2 {
  box-sizing: border-box;
  border-radius: 50%;
  background-size: cover !important;
  position: absolute;
  width: 240px;
  height: 240px;
  right: 235px;
  top: -17px;
  background: url("/screenshot-7.png");
  border: 8px solid #ffffff;
  filter: drop-shadow(0px 21px 20px rgba(60, 2, 115, 0.2));
}
@media (min-width: 320px) {
  .heroBlockImg2 {
    width: 150px;
    height: 150px;
    right: 178px;
    border: 3px solid #ffffff;
  }
}
@media (max-width: 576px) {
  .heroBlockImg2 {
    width: 120px;
    height: 120px;
    right: unset;
    left: 0px;
    border: 3px solid #ffffff;
  }
}
@media (min-width: 992px) {
  .heroBlockImg2 {
    width: 180px;
    height: 180px;
    right: 235px;
  }
}
@media (min-width: 1440px) {
  .heroBlockImg2 {
    width: 180px;
    height: 180px;
    right: 300px;
  }
}

/** -------------------- **/

.heroBlockImg3 {
  box-sizing: border-box;
  border-radius: 50%;
  background-size: cover !important;
  position: absolute;
  width: 192px;
  height: 192px;
  right: 182px;
  top: 271px;
  background: url("/screenshot-5.jpeg");
  border: 8px solid #ffffff;
  filter: drop-shadow(0px 15px 25px rgba(60, 2, 115, 0.2));
}

@media (min-width: 320px) {
  .heroBlockImg3 {
    width: 136px;
    height: 136px;
    right: 182px;
    top: 143px;
    border: 3px solid #ffffff;
  }
}

@media (max-width: 576px) {
  .heroBlockImg3 {
    width: 170px;
    height: 170px;
    right: -10px;
    top: 127px;
    border: 3px solid #ffffff;
  }
}
@media (min-width: 992px) {
  .heroBlockImg3 {
    width: 170px;
    height: 170px;
    right: 182px;
    top: 201px;
  }
}

@media (min-width: 1440px) {
  .heroBlockImg3 {
    width: 170px;
    height: 170px;
    right: 271px;
    top: 170px;
  }
}

.heroBlockImg4 {
  /*box-sizing: border-box; */
  /* border-radius: 50%; */
  background-size: cover !important;
  background-position-x: -48px !important;
  position: absolute;
  width: 468px;
  height: 361px;
  right: -80px;
  bottom: 0px;
  display: none;
  background: url("/hero-image-9.png");
  /* background-color: #35353529; */
  /* border: 6px solid #ffffff; */
  filter: drop-shadow(0px 15px 25px rgba(60, 2, 115, 0.2));
}

.heroGraphics1 {
  position: absolute;
  left: 20px;
  bottom: 8px;
  opacity: 0.5;
}
@media (min-width: 320px) {
  .heroGraphics1 {
    display: none;
  }
}
@media (min-width: 768px) {
  .heroGraphics1 {
    display: block;
  }
}

.featGraphics2 {
  position: absolute;
  right: -10%;
  top: -10%;
}
.featGraphicsTestim {
  position: absolute;
  left: calc(50% - 365px);
  top: 77px;
  transform: rotate(180deg);
}
.featGraphics5 {
  position: absolute;
  left: 5%;
  top: calc(50% - 200px);
}
.featGraphics6 {
  position: absolute;
  right: 8%;
  top: calc(50% - 200px);
  display: none;
}
@media (min-width: 768px) {
  .featGraphics6 {
    display: block;
  }
}

.featGraphics7 {
  position: absolute;
  left: 4%;
  top: 50px;
}
.featGraphics8 {
  position: absolute;
  right: 8%;
  top: 30px;
}

.illustrationSvg {
  width: 100%;
  margin: 0 auto;
  padding-top: 20px;
  display: flex;
  justify-content: center;
  /* background-color: #35353529; */
}

.illustrationCol {
  margin-top: 0%;
}
.illustrationCol-only-md-top {
  display: block;
}
.illustrationCol-only-md-bottom {
  display: none;
}
@media (min-width: 320px) {
  .illustrationCol {
    margin-top: 4%;
  }
}
@media (min-width: 576px) {
  .illustrationCol {
    margin-top: 0%;
  }
}
@media (min-width: 768px) {
  .illustrationCol-only-md-top {
    display: none;
  }
  .illustrationCol-only-md-bottom {
    display: block;
  }
}

.textCol {
  margin-top: 0%;
}
@media (min-width: 320px) {
  .textCol {
    margin-top: 1%;
  }
}
@media (max-width: 576px) {
  .textCol {
    margin-top: 0%;
  }
}
@media (min-width: 992px) {
  .textCol {
    margin-top: 3%;
  }
}

.FeaturesBlock {
  text-align: center;
  justify-content: center;
  /* height: 360; */
  align-items: center;
  display: flex;
  width: 100%;
  grid-row: 2;
  margin-bottom: 30px;
}

.FeaturesBlockWrap {
  display: flex;
  flex-wrap: wrap;
  max-width: 256px;
  margin-top: 30px;
}

.featureIcon {
  font-size: 42px;
  margin: 0 auto;
}

.FeaturesBlockWrapCenter {
  flex-wrap: wrap;
  margin-top: 5%;
  margin-bottom: 5%;
  justify-content: center;
  display: flex;
}

.HeadLineFeaturesBlock {
  color: rgba(0, 0, 0, 0.75);
  font-size: 28px;
  font-weight: 600;
  margin-top: 15px;
  margin-bottom: 50px;
  line-height: 40px;
  opacity: 0.8;
  font-weight: 500;
}
.HeadLineFeaturesBlock::before {
  content: "";
  height: 30px;
  position: absolute;
  border-radius: 15px;
  width: 30px;
  background-image: linear-gradient(
    to right,
    var(--primary-color),
    var(--processing-color)
  );
  top: 25px;
  z-index: -1;
  opacity: 0.5;
  display: none;
}
@media (min-width: 320px) {
  .HeadLineFeaturesBlock {
    margin-top: 20px;
  }
}
@media (min-width: 991px) {
  .HeadLineFeaturesBlock {
    margin-bottom: 50px;
    font-size: 30px;
    margin-top: 20px;
  }
}
@media (min-width: 1199px) {
  .HeadLineFeaturesBlock {
    margin-bottom: 75px;
    font-size: 30px;
  }
}

.featureSepGraphics {
  height: 5px;
  background-color: linear-gradient(
    to right,
    var(--primary-color),
    var(--processing-color)
  );
  width: 30%;
  background: #000;
  background-clip: padding-box;
  background-image: linear-gradient(
    to right,
    var(--primary-color),
    var(--processing-color)
  );
  border-radius: 4px;
}
@media (min-width: 375px) {
  .featureSepGraphics {
    margin-top: 10px;
  }
}
@media (min-width: 576px) {
  .featureSepGraphics {
    margin-top: 10px;
  }
}
@media (min-width: 1199px) {
  .featureSepGraphics {
    margin-top: 10px;
  }
}

.subLineFeaturesBlock {
  padding-top: 10px;
  margin-bottom: 0px;
  color: #828282;
  display: inline-block;
  /* font-weight: 600;
  font-size: 12px;
  line-height: 1.5; */
}

.HeadlineSpace {
  max-width: 374px;
}

.tabHeaderRounded {
  padding-right: 20px;
  padding-left: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 21px;
  width: 230px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  color: var(--primary-color);
  background-color: white;
  border: var(--primary-color) 2px solid;
  margin-top: 4px;
}
@media (max-width: 576px) {
  .tabHeaderRounded {
    padding-right: 2px;
    padding-left: 2px;
    padding-top: 5px;
    padding-bottom: 5px;
    border-radius: 23px;
    width: 26vw;
    font-size: 13px;
    font-weight: 600;
    height: 71px;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }
}

.tabHeaderRounded:hover {
  opacity: 0.7;
  transition: all 0.3s;
}

.tabHeaderRoundedActive {
  background-color: var(--primary-color);
  color: white;
}

.blockWrapPricing {
  padding-left: 8.33333333%;
  padding-right: 8.33333333%;
  text-align: center;
  background: #f6ebff;
  padding-bottom: 4%;
  position: relative;
}

.pricingBlock {
  width: 100%;
}
.pricingBlock :global(.tag::first-letter) {
  font-family: var(--font-system);
}

.pricingBlock :global(.Grid) {
  justify-content: center;
}
.pricingBlock :global(.Grid-cell) {
  display: flex;
  justify-content: center;
}
.pricingBlock :global(.Grid-cell > ul) {
  max-width: 220px;
  min-width: 196px;
}

.pricingBlockItem {
  width: 270px;
  border-radius: 10px;
  box-shadow: 0px 30px 70px rgb(41 27 198 / 20%);
  margin-bottom: 30px;
}
@media (min-width: 576px) {
  .pricingBlockItem {
    margin-right: 30px;
  }
}

.pricingBlockItemHeader {
  height: 150px;
  background-color: var(--processing-color);
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding: 10px;
}
.pricingBlockItemHeaderPlanName {
  color: white;
  font-size: 24px;
  font-weight: 500;
  text-align: center;
}
.pricingBlockItemHeaderPlanDescription {
  color: white;
  font-size: 17px;
  text-align: center;
  opacity: 0.8 !important;
}
.pricingBlockItemBody {
  background-color: white;
  text-align: left;
  padding: 5%;
  display: flex;
  flex-direction: column;
  height: 390px;
  justify-content: space-between;
}
.pricingBlockItemAmount {
  font-size: 24px;
  color: var(--processing-color);
  text-align: center;
  margin: -15px auto;
  font-weight: 800;
}
.pricingBlockItemAmountSubTexts {
  text-align: center;
  margin: 10px auto;
  font-weight: normal;
  color: var(--grey-1);
}
.pricingBlockItemFooter {
  height: 60px;
  background-color: var(--processing-color);
  text-align: center;
  padding: 4%;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  font-size: 20px;
  cursor: pointer;
  color: white;
  font-weight: 500;
}

.pricingBlockItemFooter:hover {
  transition: all 0.3s;
  opacity: 0.7;
}

.finalBlockOnlyCol {
  display: flex;
  justify-content: center;
  margin-top: 50px;
  margin-bottom: 50px;
}

.finalBlockWrap {
  width: 100%;
  text-align: center;
}

.girlIllustration {
  position: absolute;
  /* bottom: -10; */
  left: 20px;
  width: 160px;
  height: 120px;
  bottom: 30px;
  display: none;
}

.dataSafetyWrap {
  background: #a6a6a6b3;
  align-items: center;
  justify-content: center;
  display: flex;
  position: relative;
  padding-top: 30px;
  padding-bottom: 30px;
  box-shadow: #35353529 inset 0px 0px 28px;
}
.dataSafetyItemImg {
  width: 100px;
  padding: 26px !important;
  margin-top: 5px;
  border-radius: 50%;
  background-color: white;
  box-shadow: 0px 30px 70px rgb(0 0 0 / 30%);
  transition: all 0.5s;
  /* box-shadow: 5px 5px 18px #d0d0d0, -5px -5px 18px #f0f0f0; */
}

.dataSafetyItemImg:hover {
  background-color: var(--grey-3);
  box-shadow: unset;
  cursor: pointer;
  transition: all 0.3s;
}

.dataSafetyHeaderText {
  text-align: center;
  color: var(--grey-1);
  font-size: 24px;
  font-weight: 600;
}

.dataSafetyItemText {
  width: 100;
  text-align: "center";
  color: var(--grey-1);
  font-size: 15px;
  font-weight: 500;
  width: 120px;
  text-align: center;
}

.partnerHeader {
  margin-bottom: 20px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 30px;
  font-weight: 500;
  margin-top: 40px;
}
@media (min-width: 320px) {
  .partnerHeader {
    margin-top: 20px;
  }
}

.partnerItemWrap {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.partnerItemWrapCol {
  display: flex;
  justify-content: center;
}
.partnerItem {
  max-width: 320px;
  box-shadow: 0px 5px 12px rgb(41 27 198 / 20%);
  border-radius: 10px;
  margin-right: 15px;
  margin-bottom: 2%;
  background: #ecf7ff;
  padding: 12px;
}
@media (min-width: 320px) {
  .partnerItem {
    margin-right: 5px;
  }
}
@media (min-width: 786px) {
  .partnerItem {
    margin-right: 15px;
  }
}

@media (min-width: 991px) {
  .partnerItem {
    margin-right: 25px;
  }
}
@media (min-width: 1440px) {
  .partnerItem {
    margin-right: 15px;
  }
}

.partnerItemImage {
  width: 100%;
  overflow: hidden;
  height: 200px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.partnerItemImage > img {
  width: 100%;
}

.partnerItemName {
  text-align: left;
  padding-top: 5%;
}
.partnerItemBio {
  text-align: left;
  /* padding: 5%; */
  padding-top: 12px;
  padding-bottom: 5%;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  font-size: 13px;
}

.spaceHeader {
  justify-content: space-around;
}

.useCasesRow {
  height: 450px;
}
@media (min-width: 320px) {
  .useCasesRow {
    height: unset;
  }
}

.useCasesTextWrapper {
  text-align: left;
}
@media (min-width: 320px) {
  .useCasesTextWrapper {
    margin-top: 0px;
  }
}
@media (min-width: 576px) {
  .useCasesTextWrapper {
    margin-top: 40px;
  }
}

.useCasesSubtitle {
  margin-top: 10px;
}
@media (min-width: 320px) {
  .useCasesSubtitle {
    text-align: center;
  }
}
@media (min-width: 576px) {
  .useCasesSubtitle {
    text-align: left;
  }
}

.useCasesBody {
  font-size: 16px;
  margin-top: 10px;
}
.useCasesBulletWrapper {
  margin-top: 20px;
  margin-left: 20px;
}

.useCasesBulletItem {
  padding: 5px;
}
.useCasesImageWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.useCasesImageWrapper > img {
  width: 300px;
}
@media (min-width: 320px) {
  .useCasesImageWrapper > img {
    width: 170px;
  }
}
@media (min-width: 576px) {
  .useCasesImageWrapper > img {
    width: 300px;
  }
}

.useCasesLetsConBtn {
  margin-top: -30px;
}
@media (min-width: 320px) {
  .useCasesLetsConBtn {
    margin-top: 3px;
  }
}

.useCasesPartnerWithUsBtn {
  margin-top: -30px;
  margin-left: 15px;
}
@media (min-width: 320px) {
  .useCasesPartnerWithUsBtn {
    margin-top: 3px;
  }
}

.premiumServicesAreYouCA {
  margin-top: 20px;
  margin-bottom: 7px;
  opacity: 1 !important;
  font-weight: 700 !important;
  letter-spacing: 1px;
}

.featureTitle {
  font-size: 28px;
  font-weight: 600;
  margin-top: 10px;
}

@media (min-width: 320px) {
  .featureTitle {
    text-align: center;
    font-size: 24px;
    margin-top: 0px;
  }
}
@media (min-width: 576px) {
  .featureTitle {
    text-align: left;
  }
}
@media (min-width: 1199px) {
  .featureTitle {
    text-align: left;
    font-size: 30px;
    margin-top: 0px;
  }
}

.featureBody {
  margin-top: 10px;
  font-weight: 500;
  text-align: center;
  color: #828282;
  font-size: 15px;
}
@media (min-width: 768px) {
  .featureBody {
    font-size: 15px;
    /* margin-bottom: 20px; */
    font-weight: 500;
    text-align: left;
  }
}
@media (min-width: 992px) {
  .featureBody {
    font-size: 18px;
  }
}
@media (min-width: 1199px) {
  .featureBody {
    font-size: 20px;
    /* margin-bottom: 75px; */
  }
}

.featureBlock {
  background-color: #eaf4ff;
  text-align: center;
  justify-content: center;
  /* height: 360; */
  align-items: center;
  display: flex;
  width: 100%;
  grid-row: 2;
  padding-bottom: 30px;
  position: relative;
}

.featureItem {
  text-align: left;
  width: 100%;
}
.featureItem-md-if {
  display: none;
}
.featureItem-md-else {
  display: flex;
}
@media (min-width: 320px) {
  .featureItem {
    margin-bottom: 30px;
  }
}
@media (min-width: 786px) {
  .featureItem {
    margin-bottom: 10%;
  }
  .featureItem-md-if {
    display: flex;
  }
  .featureItem-md-else {
    display: none;
  }
}

.featureItemIllust {
  width: 100%;
}
@media (max-width: 576px) {
  .featureItemIllust {
    margin-top: 20px;
    height: 175px;
  }
}

.featureImg {
  position: absolute;
  right: 60px;
  top: 0px;
}
@media (min-width: 320px) {
  .featureImg {
    right: 0px;
    height: unset;
    width: 100%;
  }
}
@media (min-width: 576px) {
  .featureImg {
    right: 0px;
    height: unset;
    width: 100%;
  }
}
@media (min-width: 768px) {
  .featureImg {
    right: 0px;
    top: 25px;
    height: unset;
    width: 100%;
  }
}
@media (min-width: 991px) {
  .featureImg {
    position: absolute;
    height: 200px;
    width: unset;
    right: 0px;
    top: 20px;
  }
}
@media (min-width: 1199px) {
  .featureImg {
    position: absolute;
    height: 250px;
    width: unset;
    right: 30px;
    top: 20px;
  }
}
/* ----------------------------------------------------------- */

.featureImgReverse {
  right: unset;
  left: 60px;
}
@media (min-width: 320px) {
  .featureImgReverse {
    left: 0px;
    height: unset;
    width: 100%;
  }
}
@media (min-width: 576px) {
  .featureImgReverse {
    left: 0px;
    height: unset;
    width: 100%;
  }
}
@media (min-width: 768px) {
  .featureImgReverse {
    left: 0px;
    top: 25px;
    height: unset;
    width: 100%;
  }
}
@media (min-width: 991px) {
  .featureImgReverse {
    right: unset;
    left: 0px;
    top: 20px;
    height: 200px;
    width: unset;
  }
}

@media (min-width: 1199px) {
  .featureImgReverse {
    right: unset;
    left: 20px;
    top: 20px;
    height: 250px;
    width: unset;
  }
}

.featureBackGraphics {
  position: absolute;
  /* background: #ffc107; */
  background-color: var(--processing-color);
  opacity: 0.1;
  border-radius: 30px;
  transform: rotate(5.79deg);
}
@media (min-width: 320px) {
  .featureBackGraphics {
    right: 0px;
    top: 0px;
    height: 175px;
    width: 100%;
  }
}
@media (min-width: 576px) {
  .featureBackGraphics {
    width: 300px;
    height: 200px;
    right: 0px;
    top: 20px;
  }
}
@media (min-width: 1199px) {
  .featureBackGraphics {
    right: 0px;
    top: 0px;
    height: 275px;
    width: 100%;
  }
}
@media (min-width: 1600px) {
  .featureBackGraphics {
    width: 470px;
  }
}

.graphicsRotateReverse {
  width: 300px;
  height: 200px;
  transform: rotate(-5.79deg);
  left: unset;
  left: 0px;
  top: 20px;
}
@media (min-width: 320px) {
  .graphicsRotateReverse {
    right: 0px;
    top: -15px;
    height: 175px;
    width: 100%;
  }
}
@media (min-width: 576px) {
  .graphicsRotateReverse {
    width: 300px;
    height: 200px;
    right: 0px;
    top: 20px;
  }
}
@media (min-width: 1199px) {
  .graphicsRotateReverse {
    width: 100%;
    height: 275px;
    right: 0px;
    top: 20px;
  }
}
@media (min-width: 1600px) {
  .graphicsRotateReverse {
    width: 470px;
  }
}

.otherFeatureItemsWrapper {
  margin: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.otherFeatureItem {
  padding: 20px;
  width: 230px;
  height: 175px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  box-shadow: 0px 30px 70px rgba(41, 27, 198, 0.2);
  border-radius: 20px;
  margin: 12px;
  z-index: 100;
  /* box-shadow: 20px 20px 60px rgba(41, 27, 198, 0.2), -20px -20px 60px #ffffff; */
}

.otherFeatureItem > img {
  width: 67px;
  height: 67px;
}
.otherFeatureItem > div {
  text-align: center;
}

.blockWrapFooter {
  padding-left: 8.33333333%;
  padding-right: 8.33333333%;
  background: var(--processing-color);
}

.footer {
  display: "flex";
  height: 300px;
  justify-content: space-between;
}

.footerColumn1 {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  color: white;
  margin-top: 11px;
  padding-top: 6px;
}
@media (min-width: 320px) {
  .footerColumn1 {
    border-top: #ececec 1px dotted;
  }
}

.footerColumn1 > div {
  font-size: 11px;
  font-weight: 500;
  margin: 10px;
}
.footerColumn2 {
  display: flex;
  flex-direction: column;
  color: white;
  margin-top: 11px;
  padding-top: 6px;
}
.footerColumn2 > div {
  font-size: 15px;
  font-weight: 500;
  margin: 3px;
}
@media (min-width: 320px) {
  .footerColumn2 {
    border-top: #ececec 1px dotted;
  }
}

.footerColumn3 {
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  /* align-items: center; */
  margin-top: 11px;
  padding-top: 6px;
}

.footerColumn3 > div {
  color: white;
  font-size: 15px;
  font-weight: 500;
}
.footerColumn3 img {
  margin-right: 8px;
}
@media (min-width: 320px) {
  .footerColumn3 {
    border-top: #ececec 1px dotted;
  }
}

.footerColumn4 {
  display: flex;
  flex-direction: column;
  margin-top: 11px;
  padding-top: 6px;
}

.footerColumn4 > div {
  color: white;
  font-size: 15px;
  font-weight: 500;
}
@media (min-width: 320px) {
  .footerColumn4 {
    border-top: #ececec 1px dotted;
  }
}

.blockWrapTestimonials {
  padding-left: 8.33333333%;
  padding-right: 8.33333333%;
  background: #f6ebff;
  padding-bottom: 4%;
  display: flex;
  text-align: center;
  position: relative;
}

.TestimonialCarouselWrap {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
}
.testimonialCard {
  border-radius: 10px;
  background-color: transparent;
  display: flex;
  text-align: left;
  margin: 10px;
  margin: 0 auto;
  border-radius: 10px;
  justify-content: center;
}
@media (min-width: 320px) {
  .testimonialCard {
    height: 350px;
  }
}
@media (min-width: 768px) {
  .testimonialCard {
    height: 300px;
  }
}

.testimonialCardLeft {
  width: 200px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  display: none;
}
@media (min-width: 768px) {
  .testimonialCardLeft {
    display: block;
  }
}

.testimonialImage-no-md {
  display: block !important;
}
@media (min-width: 768px) {
  .testimonialImage-no-md {
    display: none !important;
  }
}
.testimonialImage {
  height: 100%;
  overflow: hidden;
  border-radius: 10px;
}
.testimonialCardRight {
  width: 400px;
  background-color: white;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}
@media (max-width: 576px) {
  .testimonialCardRight {
    background-color: white;
    border-top-left-radius: 10px;
    border-bottom-right-radius: 10px;
    border-radius: 10px;
    margin: 3px;
  }
}

.testimonialCardName {
  color: var(--grey-1);
  font-size: 17px;
  font-weight: 600;
  margin-bottom: 0px;
}
.testimonialCardPosition {
  color: var(--grey-2);
  font-size: 15px;
  font-weight: 500;
}
.testimonialCardBody {
  background-color: var(--processing-color);
  font-size: 14px;
  font-weight: 500;
  color: white;
  padding: 10px;
  width: 400px;
  text-align: justify;
}
@media (max-width: 576px) {
  .testimonialCardBody {
    width: unset;
    background-color: white;
    color: var(--grey-2);
    font-weight: 600;
    border-radius: 10px;
    text-align: left;
  }
}

.testimonialUserCount {
  font-family: none;
  font-size: 50px;
  font-weight: 800;
  /* color:rgb(0 0 0 / 30%); */
  background-image: linear-gradient(
    to right,
    var(--primary-color),
    var(--processing-color)
  );
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  line-height: 50px;
  margin-top: 30px;
}
.testimonialUserCountSub {
  font-size: 15px;
  font-weight: 600;
  color: rgb(0 0 0 / 90%);
}

.InclubationetailsBox {
  position: relative;
  width: 100%;
  height: 20%;
  padding: 2vh;
}
.theTitle {
  font-size: 20px;
}
.IIMB {
  display: inline-block;

  height: 18vh;
  margin-left: -1%;
  padding-top: 2%;
  min-height: 63px;
  min-width: 162px;
}
.IIM {
  display: inline-block;

  height: 18vh;
  margin-left: 2%;
  padding-top: 2%;
  min-height: 62px;
  min-width: 55px;
}
