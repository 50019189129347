@import "antd/es/style/themes/default.less";
@import "../../antd.theme.less";

.mainLayoutMargin {
  overflow: hidden;
  margin-left: 0;
  --color-admin: fade(@admin-color, 20%);
  --color-employee: @enployee-color;
  --color-ca: fade(@ca-color, 20%);
  --color-superuser: fade(@success-color, 20%);
  --color-deligateuser: fade(@success-color, 20%);
  --admintext-color: @admin-color;
  --catext-color: @ca-color;
  --superusertext-color: @success-color;
}

.changeWrap {
  display: flex;

  align-items: center;
}

.changeBtn {
  border-radius: 70px;
  right: 20px;
  background: "#ffff";
  font-size: 12px;
  padding: 6px 20px;
  align-items: center;
  display: flex;
  line-height: initial;
}

.mainLayout {
  overflow: hidden;
  --color-admin: fade(@admin-color, 20%);
  --color-employee: @enployee-color;
  --color-ca: fade(@ca-color, 20%);
  --color-superuser: fade(@success-color, 20%);
  --color-deligateuser: fade(@success-color, 20%);
  --admintext-color: @admin-color;
  --catext-color: @ca-color;
  --superusertext-color: @success-color;
  margin-left: 56px;
}

.companyNameDiv {
  width: 100%;
  height: 50px;
}

.commonContent {
  margin-left: calc(var(--margin-lg) + var(--margin-xs));
  margin-right: var(--margin-lg);
  margin-bottom: var(--margin-lg);
}

@media (max-width: 768px) {
  .commonContent {
    margin-left: var(--margin-sm);
    margin-right: var(--margin-sm);
  }
}

@media (max-width: 576px) {
  .commonContent {
    margin-left: var(--margin-xs);
    margin-right: var(--margin-xs);
  }
}

@media (max-width: 480px) {
  .commonContent {
    margin-left: var(--margin-xss);
    margin-right: var(--margin-xss);
  }
}

.fullWidthContent {
  margin: 0px;
}
