.jsonPreviewTable {
  margin-right: auto;
  padding: 5px 0px 5px 50px;
  color: #2f80ed;
  text-transform: capitalize;
  /* border: 1px solid black;
  width: auto;
  border-collapse: collapse; */
}
.jsonPreviewTableText {
  margin-right: auto;
  padding: 5px 0px 5px 50px;
  color: #2f80ed;
  text-transform: capitalize;
  /* border: 1px solid black;
  width: auto;
  border-collapse: collapse; */
}
.ArrayButton {
  padding: 3px 6px;
}
.greenColor {
  color: green;
  border-color: green;
}
.tbody1 {
  vertical-align: top;
  border-radius: 50%;
}
.tbodyText1 {
  vertical-align: top;
  border-radius: 50%;
}

.resetButton {
  position: absolute;
  right: 292px;
  top: 4px;
}
.editJsonB {
  justify-content: center;
  font-size: medium;
  position: absolute;
  right: 195px;
  top: 6px;
}

.ButtonExport {
  position: absolute;
  right: 40px;
  top: 4px;
}
.trowD1 {
  padding-top: 5px;
  transition: background 0.3s ease-in-out;
  /* border: 1px solid black; */
  vertical-align: top;
}
.showHideDataBtn {
  min-width: 173.6px;
  text-align: left;
}
.jsonViewInput {
  font-size: 14px;
  padding: 4px 11px;
  border: 1px solid #d9d9d9;
  border-radius: 6px;
  color: black !important;
  max-width: 173.6px;
}

.tdata1 {
  text-align: left;
}

.jsonViewInput:hover {
  border: 1px solid #69b0e7;
  cursor: pointer;
}
.jsonViewInput:focus {
  outline: 1px solid #69b0e7;
  cursor: pointer;
}

.trow1 {
  border: none !important;
}
.arrayTr {
  border: 10px solid #f7f7f7;
  background-color: #f7f7f7;
  padding: 8px;
  text-align: center;
}
/* .arrayTr:nth-child(even) {
  border: 10px solid #f7f7f734;
  background-color: #f7f7f734;
  padding: 8px;
  padding-top: 20px;
  text-align: center;
} */
.jsonPreview {
  position: relative;
  top: 45px;

  width: 102%;
  height: 400px;
  margin: 0;
  padding: 0;
  overflow: auto;
  /* overflow-x: scroll; */
  scroll-behavior: smooth;

  white-space: nowrap;
}
.jsonPreview::-webkit-scrollbar {
  width: 15px;
}

/* Track */
.jsonPreview::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px grey;
  border-radius: 10px;
}

/* Handle */
.jsonPreview::-webkit-scrollbar-thumb {
  background: #acabab;
  border-radius: 10px;
}

/* Handle on hover */
.jsonPreview::-webkit-scrollbar-thumb:hover {
  background: #acabab;
}
.containerJson {
  position: relative;

  width: 100%;
}

.setTable {
  margin-left: -250px;
  margin-top: 45px;
}
