.attatchableField {
  width: 100%;
}

.attatchableField :global(.ant-form-item-label) {
  padding: 0px;
}

.attatchableField label {
  width: 100%;
}

.attatchableField :global(.ant-row) {
  width: 100%;
}

.attachLabel {
  color: var(--primary-color);
  cursor: pointer;
}

.dragging {
  border: 4px dashed #ccc;
}

.fileIconBox {
  height: 32px;
  width: 32px;
  font-size: 32px;
  line-height: 32px;
  border-radius: 4px;
  margin-bottom: 16px;
  cursor: pointer;
}

.deleteBtn {
  height: 32px;
  margin-bottom: 16px;
  padding: 8px;
  cursor: pointer;
  font-size: 14px;
  line-height: 16px;
  color: var(--primary-color);
}

.previewModal {
  min-height: 75vh;
  min-width: calc(550px + 24px);
  /* max-width: 90vw !important; */
  max-height: 90vh !important;
}

.previewFrame {
  height: 100%;
  width: 100%;
  min-height: 70vh;
  /* height: calc(100% - 40px);  */
}

.embed {
}

/* Customize website's scrollbar like Mac OS
Not supports in Firefox and IE */

/* total width */
.embed::-webkit-scrollbar {
  background-color: #fff;
  width: 16px;
}

/* background of the scrollbar except button or resizer */
.embed::-webkit-scrollbar-track {
  background-color: #fff;
}

/* scrollbar itself */
.embed::-webkit-scrollbar-thumb {
  background-color: #babac0;
  border-radius: 16px;
  border: 4px solid #fff;
}

/* set button(top and bottom of the scrollbar) */
.embed::-webkit-scrollbar-button {
  display: none;
}
/* 
.iframediv {
  position: relative;
  height: 297px;
  width: 210px;
  overflow: hidden;
} */

.drop_file_text {
  text-align: "center";
  font-weight: 700;
  margin-top: "10px";
}

.warning_holder {
  display: "flex";
  flex-direction: "row";
}
