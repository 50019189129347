#addcustomcompliance-modal {
  position: fixed;
  top: 88%;
  left: 92%;
  z-index: 1000;
}

#addcompliance-icon {
  zoom: 130%;
  margin-top: 4px;
}

.mt-1 {
  margin-top: 2em;
}

.editorClassName {
  background-color: #fafbfc;
  padding: 1rem;
  border: 1px solid rgb(220, 206, 206);
  height: 20rem;
}

.editorClassName_admin {
  background-color: #fafbfc;
  padding: 1rem;
  border: 1px solid rgb(220, 206, 206);
  min-height: 30rem;
}
.inside-content {
  display: flex;
  width: 100%;
  height: 100%;
}
.inside-content-item {
  flex: 50%;
  max-width: 55%;
  position: relative;
  height: 100%;
  margin: 0 !important;
}

.inside-content-item2 {
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 0;
  margin: 0;
  flex: 50%;
  max-width: 45%;
  position: relative;
  height: 100%;
}
.NetSale-inside-content {
  display: grid;
  grid-template-rows: auto auto;
  width: 100%;
  height: 80%;
}
.NetSale-inside-content-item {
  display: flex;
  justify-content: center;
  padding-bottom: 10px;
  width: 100%;
  position: relative;
  margin: 0 !important;
}
.NetSale-inside-content-item2 {
  display: flex;
  width: 100%;
  justify-content: center;
  padding: 0;
  margin: 0;
  position: relative;
}
.NetSale-top-item-list {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-evenly;
  flex-wrap: wrap;
}
.top-item-list {
  height: 85%;
}
.pie-chart {
  padding: 0%;
  margin: 0%;
  width: 100%;
  height: 100%;
}
.piechart {
  position: relative;
  top: 10px;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
}
.tiles-row {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.row-three {
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  max-width: 100%;
  margin-bottom: 2em;
}

.main-section-dashboard {
  width: 100%;
  height: 100%;
}

.flex-two-dashboard {
  display: flex;
  margin-top: 20px;
  height: 23%;
  margin-left: 30px;
  margin-right: 30px;
  justify-content: space-between;
}
.card-el-mt {
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.4399999976158142px;
  text-align: center;
  margin-bottom: 5px;
}
.tile-text {
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.4399999976158142px;
  text-align: center;
}
.card-0 {
  left: 0px;
  top: 0px;
  /* box-shadow: 0 8px 16px 0 #bababa; */
  transition: 0.3s;
  border-radius: 9px;
  background-color: white;
  text-align: center;
  padding-top: 2em;
  padding-bottom: 2em;
  margin-bottom: 10px;
  margin-top: 10px;
}

.card-0:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}
.com-fg-color {
  /* background: rgb(155, 81, 224);
  background: linear-gradient(
    90deg,
    rgba(155, 81, 224, 1) 39%,
    rgba(47, 128, 237, 1) 100%
  );
  color: #fff; */
  background: rgb(235 235 235 / 79%);
  color: #000;
  border: 2px solid var(--grey-2);
  opacity: 0.8;
  opacity: 0.8;
}
.com-fg-color:hover {
  opacity: 0.6;
}

.inv-fg-color {
  background: rgb(235 235 235 / 79%); /* rgba(47, 128, 237, 1); */
  /* background: linear-gradient(
    90deg,
    rgba(47, 128, 237, 1) 39%,
    rgba(47, 128, 237, 1) 100%
  ); */
  color: #000;
  border: 2px solid var(--grey-2);
  opacity: 0.8;
}
.inv-fg-color:hover {
  opacity: 0.6;
}

.exp-fg-color {
  /* background: rgb(47, 128, 237);
  background: linear-gradient(
    90deg,
    rgba(47, 128, 237, 1) 49%,
    rgba(155, 81, 224, 1) 100%
  );
  color: #fff; */
  background: rgb(235 235 235 / 79%);
  color: #000;
  border: 2px solid var(--grey-2);
  opacity: 0.8;
  opacity: 0.8;
}
.exp-fg-color:hover {
  opacity: 0.6;
}

.app-fg-color {
  /* background: rgba(155, 81, 224, 1);
  background: linear-gradient(
    90deg,
    rgba(155, 81, 224, 1) 79%,
    rgba(155, 81, 224, 1) 100%
  );
  color: #fff; */
  background: rgb(235 235 235 / 79%);
  color: #000;
  border: 2px solid var(--grey-2);
  opacity: 0.8;
  opacity: 0.8;
}
.app-fg-color:hover {
  opacity: 0.6;
}

.card-inside {
  width: 100%;
  height: 100%;
  background: #ffffff;
  border: 1px solid #bdbdbd;
  border-radius: 9px;
  padding: 11px;
}

.export-button-sales {
  float: right;
  right: -40% !important;
  position: relative;
  right: 0px;
  color: #2f80ed !important;

  cursor: pointer;
}
.row-four {
  width: 820px;
  margin-bottom: 2em;
}
.top-3-sales-item {
  display: flex;
  justify-content: space-between;
  padding-top: 7px;
  padding-bottom: 7px;
  border-bottom: var(--grey-3) 1px solid;
  max-width: 100%;
  font-weight: 900;
  font-size: 12px !important;
}

.top-3-sales-item:first-child {
  /* border-top: var(--grey-3) 1px solid; */
}

.top-card-label {
  color: #4f4f4f !important;
  width: 100%;
  border-bottom: var(--primary-color) 1px solid;
  padding-bottom: 10px;
  padding-top: 5px;
  max-width: 100%;
  margin-bottom: 14px !important;
  font-size: 18px !important;
  font-weight: 700 !important;
  font-family: Quicksand;
}
.top-card-label1 {
  font-weight: 600 !important;
  font-size: 14px !important;
  color: #2f80ed !important;
  border-bottom: var(--primary-color) 1px solid;
  padding-bottom: 4px;
  width: 70%;
  margin-bottom: 5% !important;
}

.div-flex-c {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.div-flex-m {
  display: flex;
  justify-content: center;
  align-items: center;
}
.tag-h6-accept {
  color: black;
  font-size: 12px;
  margin-top: 1px;
}

.ant-table-thead > tr > th.ant-table-selection-column,
.ant-table-tbody > tr > td.ant-table-selection-column {
  text-align: center;
  width: 0px;
}
.span-1 {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  margin-top: 10px;
}
.b-1 {
  font-size: 15px;
  width: initial;
}
.b-level-1 {
  text-align: right;
  font-size: 15px;
  width: initial;
}
.span-2 {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}
.compliance-modal {
  font-size: 24px;
  color: #5796fa;
  border-bottom: 2px solid #8fb9fc;
}
.compliance-d-1 {
  display: flex;
  margin-top: -20px;
  height: 85%;
  width: 100%;
}

.compliance-d-2 {
  display: flex;
  width: 100%;
  border: 1px solid #d9d9d9;
  height: 100%;
  border-radius: 10px;
  flex-direction: column;
  padding: 18px;
}

/* Custom compliance table style   */

#cmpliancename {
  width: 50%;
  height: 2.5em;
}

#dayone-input {
  margin-left: 1em;
}
.custom-compliance-header {
  font-size: 24px;
  color: #4d8ffa;
  border-bottom: 2px solid #4d8ffa;
}

.compliance-name-d {
  display: flex;
  flex-direction: column;
}

.compliance-name-d2 {
  margin-top: 1em;
  display: flex;
  flex-direction: column;
}
.yearSelected {
  margin-top: 1em;
  display: flex;
  flex-direction: row;
}

.day-section {
  margin-top: 1em;
}

/* compliance style   */
.ant-tabs-nav-scroll {
  white-space: nowrap;
  background-color: #f4f5f8;
  text-align: start;
}

.main-section-com {
  min-width: 100%;

  height: 100%;
  background: white;
  position: relative;
}
.flex-one-com {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-left: -30px;
}
.flex-one-com-child {
  border-radius: 20px;
  width: 290px;
  padding: 4px 6px;
  margin-left: 30px;
  height: 44px;
  margin-top: 0px;
}
.com-heading {
  margin-top: 0px;
  margin-left: -50px;
  color: #4d8ffa;
}
.heading {
  font-size: 24px;
  margin-left: 5px;
  letter-spacing: 2px;
}
.t-1 {
  width: 93%;
  text-align: center;
  display: flex;
  justify-content: space-between;
  margin-left: 10px;
  margin-right: 20px;
}
.t-2 {
  width: 290px;
  /* height: 200px; */
  text-align: center;
  /* display: flex; */
  margin-left: 10px;
  /* justify-content: space-between; */
  flex-direction: column;
  padding: 10px;
  /* border: 0.5px solid black ; */
  border-radius: 10px;
  box-shadow: 0 10px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  background-color: #f4f5f8;
}
.t-2:hover {
  box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.2);
}
.s1 {
  /* display: 'flex', 
width: '100%',
 justifyContent: 'space-between',
 flexDirection: 'row' */
  display: flex;
  width: 100%;
  justify-content: space-between;
  flex-direction: row;
}
.beforetable-com {
  display: flex;
  /* margin-left: 10px; */
  margin-right: 0px;
  /* max-height: 1000px; */
  /* height: 1000px; */
  /* overflow-y: scroll; */
  /* margin-top: 20px; */
  /* border: 5px solid #f4f5f8; */
  /* border-radius: 10px; */
  margin-bottom: 10px;
  /* background-color: #f4f5f8; */
}

/* .tabs{
  height: 80%;
  margin-top: 10px;
  margin-left: 30px;
  overflow-y: scroll;
  margin-right: 30px;
  background-color: white;
  border-radius: 20px;
} */
.card {
  /* Add shadows to create the "card" effect */
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  /* height: 20px;
  width: 20px; */
}

/* On mouse-over, add a deeper shadow */
.card:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

/* Add some padding inside the card container */
.container {
  padding: 2px 16px;
}

.company-detailss {
  width: 100%;
  background-color: #f9f6ee; /*test*/
  border-radius: 9px;
  border: 1px solid #bdbdbd;
}
.company-main-info-sec {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
}

.company-name-holder {
  display: flex;
  flex-direction: row;
}

.trade-name-logo {
  text-align: center;
  font-weight: bold;
  font-size: 10px;
}

.circle {
  background: rgb(209, 211, 211);
  border-radius: 50%;
  width: 15px;
  height: 15px;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  margin-left: 5px;
}

.name-logo {
  display: flex;
  flex-direction: row;
  margin-bottom: 1em;
  margin-top: 1em;
}

.name-logo-2 {
  max-width: 100%;
  display: flex;
  flex-direction: row;
  padding-bottom: 1em;
}

.company-r {
  max-width: 100%;
  position: relative;
}

.company-details-2 {
  display: flex;
  flex-direction: column;
}

.company-name {
  font-size: 28px;
  font-weight: normal;
  margin-bottom: 0;
  font-weight: bolder;
}

.company-trade-name {
  color: #919190;
  margin-top: -10px;
}

.company-details-2-val {
  font-weight: bold;
  display: flex;
  justify-content: center;
  font-size: large;
}

.company-details-2-label {
  display: flex;
  justify-content: center;
  font-size: 11px;
  font-weight: bold;
}
.company-address {
  font-size: 13px;
  font-weight: bold;
}
.logo-image-col {
  display: flex;
  justify-content: center;
}

.mask {
  opacity: 0.4;
  color: #000;
  width: 100%;
  left: 0;
  top: 0;
  height: 100%;
  position: fixed;
  z-index: 99999;
  pointer-events: none;
}
.card-two {
  position: relative;
  width: 100%;
  height: 100%;
  background: #ffffff;
  border: 1px solid #bdbdbd;
  box-sizing: border-box;
  border-radius: 9px;
  padding: 11px;
  margin: 0 !important;
}
.card-button {
  position: absolute;
  bottom: 10px;
  right: 10px;
}

@media only screen and (min-width: 992px) {
  .row-three-last {
    margin-top: 8;
  }

  .name-logo-2 {
    max-width: 100%;
  }
  .sync-box-col {
    margin-top: 25px;
  }
  .sync-com-box {
    height: 50%;
    width: 100%;
  }

  .card-box {
    height: 250px;
  }

  .company-main-info-sec,
  .logo-image-col {
    margin-left: 2%;
  }
}
@media only screen and (min-width: 1200px) {
  .name-logo-2 {
    max-width: 80%;
  }
  .card-box {
    height: 100%;
  }

  .logo-image-col,
  .company-main-info-sec {
    margin-left: 1px;
  }
}
@media only screen and (max-width: 1500px) and (min-width: 1200px) {
  .inside-content-item,
  .inside-content-item2 {
    max-width: 50%;
  }

  .top-item-list {
    height: 95%;
  }
}
@media only screen and (max-width: 1750px) {
  .list-content {
    font-size: 12px !important;
  }
}
@media only screen and (max-width: 992px) {
  .cmpny-logo,
  .company-main-info-sec {
    margin-left: 2em;
  }
  .card-box {
    height: 250px;
  }
}
@media only screen and (min-width: 1750px) {
  .list-content {
    font-size: 16px !important;
  }
}
