.contentRow {
  height: 90vh;
  width: 100%;
  margin: 0 !important;
}

.illustrationWrap {
  margin: var(--margin-lg);
  margin-left: var(--margin-xxl);
  width: 54%;
}

.illustration {
  width: 100%;
}
