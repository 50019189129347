.footerRow {
  border-bottom: 1px solid #f0f0f0;
  padding: 8px 8px;
  display: flex;
  justify-content: space-around;
}

.footer {
  min-width: 50%;
  padding-right: 3%;
}

.footerWrap {
  padding: 0px var(--padding-xs);
  text-align: left;
  display: flex;
  width: 100%;
  justify-content: flex-end;
}

.footerLabel {
  color: var(--primary-color);
  text-align: left;
  display: inline-block;
  font-weight: 500;
}

.footerAmount {
  text-align: right;
  display: inline-block;
  color: var(--text-color);
  word-wrap: break-word;
}

.select {
  width: 100%;
}

.option > div {
  max-width: 100%;
  height: auto;
  white-space: break-spaces;
  word-break: break-word;
}
/* for ph view */
@media (max-width: 831px) {
  .onLaptop {
    display: none;
  }
  .onMobile {
    display: block;
  }
}
/* for laptop version */
@media (min-width: 831px) {
  .onLaptop {
    display: inline-block;
  }
  .onMobile {
    display: none;
  }
}
