.vendor {
  width: 100%;
}

.checkbox :global(.ant-form-item-label) {
  order: 2;
  flex-basis: 85%;
}

.checkbox :global(.ant-form-item-control) {
  order: 1;
  flex-basis: 15%;
}

.invoice {
  font-size: 12px;
  margin-top: 5px;

  margin-left: 5px;
  opacity: 1;
}

.riple {
  width: 32px;
  height: 22px;
  border-radius: 25px;
  background-color: #dbf6c6;
  padding-left: 2px;

  padding-bottom: 35px;

  animation: blinker 1s linear infinite;
}

.commonContent {
  padding-left: calc(var(--margin-lg) + var(--margin-xs));
  padding-right: var(--margin-lg);
  padding-bottom: var(--margin-lg);
}

@media (max-width: 768px) {
  .commonContent {
    padding-left: var(--margin-sm);
    padding-right: var(--margin-sm);
  }
}

@media (max-width: 576px) {
  .commonContent {
    padding-left: var(--margin-xs);
    padding-right: var(--margin-xs);
  }
}

@media (max-width: 480px) {
  .commonContent {
    padding-left: var(--margin-xss);
    padding-right: var(--margin-xss);
  }
}

.dragOverVisible {
  composes: commonContent;
  position: relative;
  border: 2px solid var(--primary-color);
}

.dragOverLabel {
  position: absolute;
  display: none;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background-color: rgba(47, 128, 237, 0.12);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: var(--heading-3-size);
  color: var(--grey-2);
  z-index: 1000;
}

@keyframes blinker {
  from {
    background-color: #ffffff;
  }
  to {
    background-color: #72ba73;
  }
}

.save_edit_button {
  margin-right: 0.5em;
  min-width: 80px;
}
