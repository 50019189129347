.inclubationetailsBox {
  position: relative;
  width: 100%;
  height: 28vh;
  padding: 2vh;
  min-height: 125px;
}
.theTitle {
  font-size: 22px;
  width: 7px;
  animation-name: TITLE;
  overflow: hidden;
  animation-timing-function: linear;
  animation-duration: 7s;
  animation-delay: 2s;
  animation-fill-mode: backwards;
  animation-iteration-count: infinite;
}
@keyframes TITLE {
  0% {
    width: 130px;
  }
  30% {
    width: 130px;
  }
  50% {
    width: 7px;
  }
  80% {
    width: 7px;
  }
  100% {
    width: 130px;
  }
}
.containerOne {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 16vh;
  width: 14vw;
  min-width: 185px;
  min-height: 68px;
  cursor: pointer;
  transition-duration: 500ms;
}
.IIMBimage {
  height: 12vh;
  padding-left: 1%;
  min-height: 67px;
}
.containerOne:hover .IIMBimage {
  height: 11.5vh;
}
.containerTwo {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 16vh;
  width: 6vw;
  min-width: 80px;
  min-height: 68px;
  cursor: pointer;
  transition-duration: 500ms;
}
.IIMimage {
  height: 12vh;
  padding-right: 1%;
  min-height: 67px;
}
.containerTwo:hover .IIMimage {
  height: 11.5vh;
}
