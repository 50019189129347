.footerWrap {
  padding: 0px var(--padding-xs);
  text-align: left;
  display: flex;
  width: 100%;
  justify-content: flex-end;
}

.footer {
  min-width: 400px;
}

.footerRow {
  border-bottom: 1px solid #f0f0f0;
  padding: 8px 8px;
  display: flex;
  justify-content: space-between;
}

.footerLabel {
  color: var(--primary-color);
  width: 40%;
  text-align: left;
  display: inline-block;
}

.footerAmount {
  composes: currency from "../../component/Currency.module.css";
  text-align: right;
  width: 40%;
  color: var(--text-color);
}

.linkStyle:hover {
  text-decoration: underline;
}

.table {
  width: 90%;
}

.table :global(.ant-table-thead) > tr > th {
  color: var(--primary-color);
  font-weight: var(--title-font-weight);
  border-bottom: 1px solid var(--grey-1) !important;
  padding: 12px 19px;
}

.table :global(.ant-table-tbody) > tr > td {
  font-weight: var(--title-font-weight);
}

.table :global(.ant-table-footer) {
  border-top: 1px solid var(--grey-1);
  border-bottom: 1px solid var(--grey-1);
  background-color: var(--white);
  color: var(--primary-color);
  font-weight: var(--title-font-weight);
  padding: var(--padding-xs) 0px;
}

.actionCell {
  padding: 0px !important;
}

.editor {
  border: none;
}

.currencyEditor {
  composes: editor;
  text-align: right;
}
.currencyEditor input {
  text-align: right;
}

.errorSpace {
  width: 100%;
  justify-content: center;
}

.errorIcon {
  color: var(--error-color);
}

.errorList {
  padding-left: var(--padding-sm);
  margin-block-start: var(--margin-xs);
  margin-block-end: var(--margin-xs);
  margin-inline-start: var(--margin-sm);
  margin-inline-end: var(--margin-sm);
}
