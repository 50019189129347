@import "../../../app.theme.less";

.root {
  width: 100%;
  min-width: 140px;
  text-align: left;
}

.previewModal {
  width: 100%;

  :global(.ant-modal-close-x) {
    width: 38px;
    height: 38px;
    line-height: 38px;
  }

  :global(.ant-design-draggable-modal-title) {
    line-height: 1.2;
    font-size: @font-size-lg;
    padding: @padding-xs;
  }

  :global(.ant-modal-body) {
    padding: 0;
  }
}

.previewIframe {
  width: 100%;
  height: calc(100% - 10px);
}

.fileIcon {
  display: inline-block;
  font-size: @tag-line-height;
  min-width: @tag-line-height;
  vertical-align: super;
}

.iconHidden {
  display: inline-block;
  composes: hidden from "../../../component/common.module.css";
}

.vanish {
  display: none;
}

.uploadBtn {
  margin-left: @margin-md;
}

.labelBox {
  display: inline-block;
  display: inline-block;
  max-width: 112px;
  margin-left: @padding-xss + 2;

  .uploadError {
    color: @error-color;
    font-size: @font-size-sm;
  }

  label {
    display: block;
    width: 100%;
    font-size: @font-size-sm;
    color: @primary-6;
    text-align: left;
    line-height: 1;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  .actionBox {
    width: 100%;
    display: flex;
    align-items: center;
  }

  progress {
    margin-right: @margin-xss;
  }

  button {
    font-size: @font-size-sm;
    font-weight: bolder;
  }
}

.progressBar {
}

.hiddenProgress {
  composes: hidden from "../../../component/common.module.css";
}
