.hoverEffectdisable {
  /* box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2); */
  cursor: not-allowed;
}

.hoverEffect {
  /* cursor: no-drop; */
  text-decoration: none;
  border: none;
  /* padding: 12px 40px; */
  /* font-size: 16px; */
  /* background-color: green; */
  color: #fff;
  /* border-radius: 5px; */
  /* box-shadow: 7px 6px 28px 1px rgba(0, 0, 0, 0.24); */
  cursor: pointer;
  outline: none;
  transition: 0.2s all;
}

.hoverEffect:active {
  transform: scale(0.98);
  /* Scaling button to 0.98 to its original size */
  box-shadow: 3px 2px 22px 1px rgba(0, 0, 0, 0.24);
  /* Lowering the shadow */
}

.textdiv {
  margin-top: 40px;
  font-size: 18px;
}
.buttondiv {
  margin-top: 15px;
}
.errordiv {
  font-size: 14px;
  color: red;
}
.cheking {
  margin-top: 85px;
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 85px;
}
.start {
  margin-top: 85px;
}
.zohoStart {
  margin-top: 45px;
}
.back {
  width: "100%";
  margin-top: 85px;
  display: flex;
  justify-content: space-between;
}

.talyIconChoser {
  height: 75px;
  width: 75px;
  object-fit: contain;
}
.talyIcon {
  margin-top: 20px;
  height: 70px;
  /* width: 100px; */
}
.talyIcon2 {
  margin-top: 20px;
  height: 70px;
  /* width: 130px; */
}
.talyIcon3 {
  margin-top: 20px;
  height: 70px;
  /* width: 78px; */
}

.Tallyback {
  margin-top: 5px;
  display: flex;
  justify-content: space-evenly;
}

.sync_back {
  margin-right: 10px;
}

.sourceheading {
  color: var(--grey-2) !important;
}

.sourceSelectionModalBumpZIndex {
  z-index: 1001 !important;
}

/* .footerButtonContainer{
  display: flex;
  justify-content: space-between;
  align-items: center;
} */
.logo {
  height: 13px;
  /* width: 16px; */
  margin-right: 10px;
}
.ZohoIcon {
  margin-top: 35px;
  height: 50px;
}
.zohoBack {
  width: "100%";
  margin-top: 45px;
  display: flex;
  justify-content: space-between;
}

/* .allLogo{
  height: 13px;
  
} */
