.ant-upload.ant-upload-drag .ant-upload {
  padding: 0px 0px;
}

.commonContent {
  padding-left: calc(var(--margin-lg) + var(--margin-xs));
  padding-right: var(--margin-lg);
  padding-bottom: var(--margin-lg);
}


.setupButton {
  background-color: '#219653';
  color: 'white';
  border-color: '#219653';
}

.dragger {
  background-color: 'white';
  cursor: 'auto';
  padding: '4px 0px 0px';
  border: '2px solid red';
}

.successBtn {
  background-color: var(--success-color) !important;
}
.summaryDiv {
  font-size: 16px;
  font-style: normal;
  /* font-weight: 700; */
  line-height: 24px;
  letter-spacing: 0.4399999976158142px;
  text-align: left;
  margin-bottom: 0.3em;
  margin-top: 0.5em;
}
.summaryDiv > span {
  color: red;
  margin-left: 3px;
}
.approveAction {
  border: 1px solid rgb(92 247 126 / 46%);
  border-radius: 11px;
  padding: 3px;
  font-size: 11px;
  font-weight: 800;
  background-color: rgb(92 247 126 / 46%);
  cursor: pointer;
}
.rejectAction {
  border: 1px solid rgba(247, 92, 148, 0.46);
  border-radius: 11px;
  padding: 3px;
  font-size: 11px;
  font-weight: 800;
  background-color: rgba(247, 92, 148, 0.46);
  margin-left: 10px;
  cursor: pointer;
}
.reimbursementTypeOption {
  margin-bottom: var(--margin-md);
}

.footer {
  color: var(--primary-color);
  font-weight: var(--title-font-weight);
  padding: 0px var(--padding-xs);
  text-align: left;
}

.footerAmount {
  composes: footer;
  composes: currency from '../../component/Currency.module.css';
  text-align: right;
}

.table {
  width: 70%;
}

.table :global(.ant-table-thead) > tr > th {
  color: var(--primary-color);
  font-weight: var(--title-font-weight);
  border-bottom: 1px solid var(--grey-1) !important;
  padding: 12px 19px;
}

.table :global(.ant-table-tbody) > tr > td {
  font-weight: var(--title-font-weight);
}

.table :global(.ant-table-footer) {
  border-top: 1px solid var(--grey-1);
  border-bottom: 1px solid var(--grey-1);
  background-color: var(--white);
  color: var(--primary-color);
  font-weight: var(--title-font-weight);
  padding: var(--padding-xs) 0px;
}

.actionCell {
  padding: 0px !important;
}

.editor {
  border: none;
}

.currencyEditor {
  composes: editor;
  text-align: right;
}
.currencyEditor input {
  text-align: right;
}
.vendor {
  width: 100%;
}
.footerWrap {
  /* font-weight: var(--title-font-weight); */
  padding: 0px var(--padding-xs);
  text-align: left;
  display: flex;
  width: 100%;
  justify-content: flex-end;
  margin-top: 1em;
}
.approve-color {
  color: #87e182;
}
.rejected-color {
  color: #eb474d;
}
.pending-color {
  color: #23a1f2;
}
.done-clor {
  color: #23a1f2;
}
.applied-color {
  color: green;
}
