.dropdownWrap {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.table {
  width: 70%;
}

.plusBtn {
  padding: 0;
}

.tableWrapper {
  padding-bottom: var(--margin-lg);
}

.tableActionWrapper {
  display: flex;
  justify-content: right;
  width: 100%;
}

.footerRow {
  border-bottom: 1px solid #f0f0f0;
  padding: 8px 0px;
  display: flex;
  justify-content: space-between;
  /* margin-right: -10px; */
}

.footer {
  min-width: 100%;
}

.footerWrap {
  /* font-weight: var(--title-font-weight); */
  /* padding: 0px var(--padding-xs); */
  text-align: left;
  display: flex;
  width: 100%;
  justify-content: flex-end;
}

.footerLabel {
  color: var(--primary-color);
  width: 40%;
  text-align: left;
  display: inline-block;
  font-weight: 500;
}

.footerAmount {
  text-align: right;
  width: 40%;
  display: inline-block;
  color: var(--text-color);
  word-wrap: break-word;
}

.table :global(.ant-table-cell > .ant-input) {
  text-align: right;
}

@media (max-width: 768px) {
}

@media (max-width: 576px) {
}

@media (max-width: 480px) {
}
